import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Label} from './label';
// import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {LabelService} from './label.service';
import {Observable, Subject} from 'rxjs';
import {BaseListComponent} from '../../base/base-list.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigService} from '../../common/config/config.service';
import {Globals} from '../../common/global';
import { StorageService} from '../../common/service/storage.service';
import { ApiService} from '../../common/service/api.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators ,AbstractControl,ValidationErrors} from '@angular/forms';
import { IpServiceService } from '../../common/service/ip-service.service';
const timeZone = require('moment-timezone');

@Component({
    selector: 'label-table',
    templateUrl: 'label.component.html',
    styleUrls: ['label.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LabelComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewInit {

    page = 0;
    // columns = this.getColumn();
    columns : Array<any> = [];

    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    // displayedColumns = this.columns.map(c => c.columnDef);
    displayedColumns: Array<any> = [];
    length: any;
    perPageData=  this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: '',
    };
    showHtmlTemplate = false;
    // enableInfoMsg : false;

    registerFormEdit: FormGroup;

    loggedinUser;
    ipAddress= '';
   

    constructor(public paginationService: LabelService,
                private changeDetectorRefs: ChangeDetectorRef,
                public snackBar: MatSnackBar, private router: Router,
                private configService: ConfigService,
                private globals: Globals,private userService: StorageService,
                private apiService: ApiService, public dialog: MatDialog,
                private _formBuilder: FormBuilder,
                private ip:IpServiceService) {
        super(snackBar, configService, globals);




        this.registerFormEdit = this._formBuilder.group({
            currentCount: ['', Validators.required],
          lastPrintedFrom: ['', Validators.required],
          lastPrintedTo: ['', Validators.required],
          Type: ['', Validators.required],
          labelEdit: ['', Validators.required],
          minimumCount:['', [Validators.required,Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]]
            // hasLimit: ['', Validators.required],
            // validTillDate: [{value :''}, ],
        },{validator: validateValues});

    }
    /**
     * On init
     */
    ngOnInit(): void {

        // this.getIP();
       
        this.apiService.getRoleAndPermission(this.userService.getCookie('lab')).then(async ( data:any) => {
            this.loggedinUser = data.data.name;
            console.log(data.data.role);
            if(data.data.role === 'support'){
                const initCol = this.getColumn();
                  // const editColumn = {
                  //       columnDef: 'edit',
                  //       header: 'Edit',
                  //       cell: (element: Label, index: number) => `${element.id}`
                   // };
                const deleteColumn =  {
                        columnDef: 'delete',
                        header: 'Delete',
                        cell: (element: Label, index: number) => `${element.id}`
                    };


                    const editColumn =  {
                        columnDef: 'edit',
                        header: 'Edit',
                         cell: (element: Label, index: number) => `${element.id}`
                    };

                    const viewColumn =  {
                        columnDef: 'view',
                        header: 'View',
                         cell: (element: Label, index: number) => `view`
                    };

               // initCol.push(editColumn,deleteColumn);
                initCol.push(viewColumn); 
                initCol.push(editColumn); 
                initCol.push(deleteColumn);  
               
               
                       

                this.showHtmlTemplate = true;
                this.columns = initCol;
                this.displayedColumns = this.columns.map(c => c.columnDef);
                console.log( this.columns);
              
            }else{
                this.showHtmlTemplate = true;
                this.columns = this.getColumn();
                this.displayedColumns = this.columns.map(c => c.columnDef);
            }
        });

        this.page = 0;
      
        

    }


    getColumn(): any[] {
        return [
            {columnDef: 'Sr', header: 'Sr.', cell: (element: Label, index: number) => `${this.page * 10 + index + 1}`},
            {columnDef: 'labelType', header: 'Type', cell: (element: Label, index: number) => `${element.id}`},
            {
                columnDef: 'currentCount',
                header: 'CurrentCount',
                cell: (element: Label, index: number) => `${element.currentCount}`
            },
            {
                columnDef: 'minimumCount',
                header: 'Threshold',
                cell: (element: Label, index: number) => `${element.minimumCount}`
            },
            {
                columnDef: 'lastPrintFrom',
                header: 'LastPrintedFrom',
                cell: (element: Label, index: number) => `${element.lastPrintFrom}`
            },
            {
                columnDef: 'lastPrintTo',
                header: 'LastPrintedTo',
                cell: (element: Label, index: number) => `${element.lastPrintTo}`
            },
            {
                columnDef: 'lastPrintBy',
                header: 'PrintedBy',
                cell: (element: Label, index: number) => `${element.lastPrintBy}`
            },

            {
                columnDef: 'lastPrintOn',
                header: 'PrintedOn',
                cell: (element: Label, index: number) => `${this.getDate(element.lastPrintOn)}`
            },

            {
                columnDef: 'lastUsed',
                header: 'LastUsed',
                cell: (element: Label, index: number) => `${element.lastUsed}`
            },
            {
                columnDef: 'lastUsedOn',
                header: 'LastUsedOn',
                cell: (element: Label, index: number) => `${this.getDate(element.lastUsedOn)}`
            },
        ];
    }

    updateRow(row): any {
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Labels Replenishment';
    }

    getDate(numberValue): string {
        if (numberValue === 0) {
            return '-';
        } else {
            // return moment(Math.abs(numberValue)).format('DD-MM-YYYY | HH:mm');
            return this.apiService.getDateStringMomentByTz(numberValue);
        }
    }


    getRowBackgroundColor(element): string {
        if (element.currentCount < element.minimumCount) {
            return '#F29E9E';
        } else {
            return '#FFFFFF';
        }
    }

    protected getPaginationService() {
        return this.paginationService;
    }


        deleteLabel(docId) {
            this.paginationService.deleteLabelById(docId).then(result => {
                const msg = 'Label Deleted Successfully';
                this.openSnackBar(msg, 'OK');
            }).catch((error) => {
                this.handleError(error);
            });
    }

 
    editLabel(docId) {
       
        const labelData =
        {
            currentCount: Number(this.registerFormEdit.value.currentCount),
            lastPrintFrom:this.registerFormEdit.value.lastPrintedFrom,
            lastPrintTo:this.registerFormEdit.value.lastPrintedTo,
            id:docId,
            loggedinUser:this.loggedinUser,
            ipAddress:this.ipAddress,
            minimumCount:this.registerFormEdit.value.minimumCount

        };
     

        const returnData = this.paginationService.updateLabelById(labelData);
        returnData.then(data => {
           
            const msg = 'Label Updated Successfully';
                this.openSnackBar(msg, 'OK');
            this.registerFormEdit.reset();
            window.location.reload();

        }).catch((error) => {
            this.handleError(error);
        });
       

    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    openDialogWithRefDel(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: '400px' });
    }



    openDialogWithRefEdit(ref: TemplateRef<any>,row): void {

        this.registerFormEdit.setValue({
            currentCount: row.currentCount,
            lastPrintedFrom: row.lastPrintFrom,
            lastPrintedTo: row.lastPrintTo,
            Type:row.id,
            labelEdit:"Edit label-"+row.id,
            minimumCount:row.minimumCount
        });
        this.dialog.open(ref, { width: '400px' });

    }

    getDetail(id) {
        this.router.navigate(['/labeldetail'], { queryParams: { barcode: id } });
    }

    check(col)
    {
        console.log("col");
        console.log(col);
        return false;
    }
    getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{
        this.ipAddress=res.ip;
        console.log("IpAddress");
        console.log(this.ipAddress);
    });
    
  }  
}

export function validateValues(
    control: AbstractControl
  ): ValidationErrors | null {
      var fromType,toType,fromNo,toNo;
    if (control && control.get("lastPrintedTo") && control.get("lastPrintedFrom")) {
      var lastPrintedTo = control.get("lastPrintedTo").value;
      var lastPrintedFrom = control.get("lastPrintedFrom").value;
      var type = control.get("Type").value;
      fromType=lastPrintedFrom.substring(0,type.length);
      toType=lastPrintedTo.substring(0,type.length);
      fromNo=lastPrintedFrom.substring(type.length,lastPrintedFrom.length);
      toNo=lastPrintedTo.substring(type.length,lastPrintedTo.length);
    
      if(fromType != type || toType!=type)
      {
        return  { scoreErrorType: true }
      }
      else{
        var pattern = /^\d+$/;
          if(pattern.test(fromNo)!=true || pattern.test(toNo)!=true)
          {
            return { scoreErrorNumber: true }
          }
            else if(Number(fromNo) > Number(toNo))
            {
                return { scoreError: true }
            }

    }
    //   return Number(fromNo) > Number(toNo) ? { scoreError: true } : null
    //   return fromType != toType ? { scoreErrorType: true } : null
    }
    return null;
  }