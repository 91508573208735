import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseListService } from "../../base/base-list.service";
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })

export class LabelService extends BaseListService {

    protected isOrderByNegative = true;
    constructor(private afsChild: AngularFirestore, private userServiceChild: StorageService) {
        super(afsChild, userServiceChild);
        this.table = 'label';
        this.orderBy = 'lastPrintFrom';
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS));
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .limit(this.ROWS));
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .limit(this.ROWS));

    }

    getCollectionPath(): string {
        return 'lab/' + this.userServiceChild.getCookie("lab") + '/' + this.table;
    }

    getSearchQueryWithOutText(): any {
    }

    getSearchQueryWithText(): any {
    }

    deleteLabelById(docId) {
        const collectionPath = `lab/${this.userServiceChild.getCookie('lab')}/label`;
        return new Promise<any>((resolve, reject) => {
            this.afsChild.collection(collectionPath).doc(docId).delete().then((result) => {
                const msg = 'Label deleted successfully';
                resolve(msg);
            }).catch((error) => {
                reject('Error removing document');
            });
        });
    }

    updateLabelById(labelData) {

        const labelPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afsChild.collection(labelPath).doc(labelData.id).update(labelData).then((resultData) => {
                // window.alert(`Team Member ${teamData.name} Added Successfully`);
                const data = { msg: 'Updated Successfully', status: 'success' };
                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }




    getLabelById(type) {
        const collectionPath = `lab/${this.userServiceChild.getCookie('lab')}/label`;
        return new Promise((resolve, reject)=>{

            this.afsChild.collection(collectionPath).doc(type).valueChanges().subscribe(data => {
                resolve(data);

                })
            })




        // return this.afsChild.collection(collectionPath).snapshotChanges()
        //     .pipe(map(actions => {
        //         return actions.map((a: any) => {
        //             return a.payload.doc.data();
        //         });
        //     }));
    }
}
