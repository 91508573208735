import { Component, HostListener, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Patient } from "../patient";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PatientService } from "../patient.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import * as moment from "moment";
import {
    APP_DATE_FORMATS,
    AppDateAdapter,
} from "../../report/format-datepicker";
import {
    DateAdapter,
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../../common/global";
import { Router } from '@angular/router';

const timeZone = require("moment-timezone");
@Component({
    selector: "add-patient-dialog",
    templateUrl: "add-patient-dialog.component.html",
    styleUrls: ["add-patient-dialog.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
})
export class AddPatientDialogComponent implements OnInit {
    patientForm: FormGroup;
    platform = "web";
    @Input()
    min: "D" | null;
    today = new Date();
    isEnterPressed = false;
    hasFirstDataShown: Boolean;
    dobStamp = 0;
    dobString = "";
    deleted: boolean;
    rowId: any;

    constructor(
        public dialogRef: MatDialogRef<AddPatientDialogComponent>,
        private _formBuilder: FormBuilder,
        public patientService: PatientService,
        public snackBar: MatSnackBar,
        private userService: StorageService,
        private cookieService: CookieService,
        private global: Globals,
        private router: Router,
        private httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: Patient
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Validators.pattern(/^\S*$/)
        this.patientForm = this._formBuilder.group({
            // Validators.pattern(/^\S+/)
            firstName: [
                "",
                [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)],
            ],
            id: ["", [Validators.required, Validators.pattern(/^\S*$/)], ""],
            lastName: ["", [Validators.required]],
            emailId: [
                "",
                [
                    Validators.email,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ],
            ],
            mobileNo: [
                "",
                [
                    // Validators.required,
                    Validators.pattern("^((\\+91-?))?[0-9]{10}$"),
                ],
            ],
            // mobileNo: ["", [Validators.min(7)]],
            // mobileNo: ['', [Validators.pattern(/^\d{10}$/)], ''],
            dob: ["", ""],
        });

        this.setFocus();
    }

    // calculateAge(dob) {
    //     const diffMs = Date.now() - dob.getTime();
    //     const ageDt = new Date(diffMs);
    //     return Math.abs(ageDt.getUTCFullYear() - 1970);
    // }

    addPatientDetail() {
        this.hasFirstDataShown = false;
        const dobDateMonthYear = [];
        if (this.patientForm.value.dob) {
            const date = new Date(this.patientForm.value.dob);
            this.dobString = moment(
                this.patientForm.value.dob,
                "DD/MM/YYYY"
            ).format("DD/MM/YYYY");
            this.dobStamp = date.getTime();
        }
        const patientData = {
            anyMap: {},
            firstName: this.patientForm.value.firstName.trim().toUpperCase(),
            firstNameMap: {}, // initialized
            lastName: this.patientForm.value.lastName.trim().toUpperCase(),
            lastNameMap: {}, // initialized
            id: this.patientForm.value.id.trim().toUpperCase(),
            emailId: this.patientForm.value.emailId,
            mobileNo: this.patientForm.value.mobileNo
                ? this.patientForm.value.mobileNo.toString()
                : "",
            dob: this.dobStamp,
            // docId: this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName,
            idMap: {}, // initialized
            platform: this.platform,
            addedBy: this.userService.getCookie("email")
                ? this.userService.getCookie("email")
                : "NA",
            addedOn: (-1 * new Date().getTime()),
            dobString: this.dobString,
            deleted: this.deleted,
            rowId: this.rowId,
        };

        const fName = TextUtils.removeSpace(
            this.patientForm.value.firstName
        ).toUpperCase();
        const lName = TextUtils.removeSpace(
            this.patientForm.value.lastName
        ).toUpperCase();
        let id = TextUtils.removeSpace(this.patientForm.value.id).toUpperCase();
        // this.patientService.getPatientById(comboId).then(data=> {
        this.patientService.getPatientByFieldId(id).then((data) => {
            //   console.log(data[0].deleted);

            if (data && data[0].deleted == true) {
                const msg =
                    "The Patient id '" + id + "' already exists and it was deleted earlier. To add this patient again, please contact SterilWize Support Team."
                this.openSnackBar(msg, "OK");
            } else if (data) {
                const msg = "Patient ID Already Exist. ";
                this.openSnackBar(msg, "OK");
                this.router.navigate(['/patient'])
            } else {
                const splitMapFName = this.patientForm.value.firstName
                    .toUpperCase()
                    .split(" ");

                let splitMapF = {};
                for (let i of splitMapFName) {
                    splitMapF = Object.assign(splitMapF, this.getMapValue(i));
                }

                const splitMapLName = this.patientForm.value.lastName
                    .toUpperCase()
                    .split(" ");

                let splitMapL = {};
                for (let j of splitMapLName) {
                    splitMapL = Object.assign(splitMapL, this.getMapValue(j));
                }

                const firstNameMap = this.getMapValue(fName);
                const lastNameMap = this.getMapValue(lName);
                const fnamenameCombo = this.getMapValue(fName + lName);

                const idMap = this.getMapValue(id);
                const anyMap = Object.assign(
                    {},
                    idMap,
                    firstNameMap,
                    lastNameMap,
                    fnamenameCombo,
                    splitMapF,
                    splitMapL
                );
                // anyMap[fName + lName] = true;

                patientData.anyMap = anyMap;
                patientData.firstNameMap = firstNameMap;
                patientData.lastNameMap = lastNameMap;
                patientData.idMap = idMap;
                patientData.deleted = false;
                var loadInfo = {
                    orderByColumn: "desc",
                    sortByColumn: "rowId",
                    labId: this.userService.getCookie("lab"),
                };
                const headers = new HttpHeaders({
                    "content-type": "application/json",
                });
                const body = JSON.stringify(loadInfo);
                this.httpClient
                    .post<any>(this.global.constUrl + "getLastPatient", body, {
                        headers: headers,
                    })
                    .subscribe(
                        (res) => {
                            console.log(res.error);
                            if (res.error == "") {
                                console.log(res.data[0].rowId + 1);
                                patientData.rowId = res.data[0].rowId + 1;
                            } else if (
                                res.error == "No patient exist in database"
                            ) {
                                console.log(res);
                                patientData.rowId = 1;
                            } else {
                                this.openSnackBar(
                                    "Invalid rowId maintained in database. Please contact support team.",
                                    "OK"
                                );
                                return;
                            }

                            // // const returnData = this.patientService.addPatient(patientData, comboId);
                            const returnData = this.patientService.addPatient(
                                patientData,
                                id
                            );
                            returnData
                                .then((result) => {
                                    this.onNoClick(); // close dialoag
                                    this.setFocus();
                                   
                                    const msg = "Patient Added Successfully";
                                    this.openSnackBar(msg, "OK");
                                    this.patientForm.reset();
                                    this.goBack();
                                    // this.router.navigate(['/patient']);
                                    // this.patientForm.reset();
                                    // window.location.reload();
                                    this.hasFirstDataShown = true;
                                })
                                .catch((error) => {
                                    this.handleError(error);
                                });
                        },
                        (err) => {
                            console.log(err);
                            this.hasFirstDataShown = true;
                            this.router.navigate(['/patient']);
                            // do something
                        }
                    );
            }
        });
    }

    goBack() {
        this.router.navigate(["/patient"])
            .then(() => {
                // this.ngOnDestroy();
                this.ngOnInit()
                // window.location.reload();
            });
    }
    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    openSnackBar(message: string, action: string): void {
        this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 10000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key.includes("Enter") && event.code.includes("Enter")) {
            this.isEnterPressed = true;
            if (this.patientForm.valid) {
                // this.addPatientDetail();
                // this.patientForm.disable();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    isNullOrEmpty(event, firstName) {
        if (event.keyCode === 32 && firstName.length + 1 === 1) {
            const patt = /^\S+/;
            const result = patt.test(event.key);
            return result;
        } else {
            return event.key;
        }
    }

    setFocus() {
        const element = document.getElementById("id");
        element.focus();
    }

    // isNullOrEmpty(event): boolean {
    //     const patt = /^([0-9])$/;
    //     const result = patt.test(event.key);
    //     return result;
    // }
}
