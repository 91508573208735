import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FirebaseUtilService } from "../../../common/service/FirebaseUtil.service";
import { CovidSetting } from "./StaffResolutionSetting.model";


@Component({
    selector: 'app-StaffResolutionSetting',
    templateUrl: './StaffResolutionSetting.component.html',
    styleUrls: ['./StaffResolutionSetting.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class StaffResolutionSettingComponent implements OnInit, OnDestroy {
    private settingDto: CovidSetting;
    pageType: string;
    formGroup: FormGroup;
    data: any;
    serverProduct: CovidSetting;
    lab = FirebaseUtilService.LAB_PLACE_HOLDER;
    selected = 'Yes';
    selectedPatient = 'Yes';
    hasFirstDataShown = false

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private firebaseUtilService: FirebaseUtilService,
    ) {
        // Set the default
        this.settingDto = new CovidSetting();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to update product on changes

        this.firebaseUtilService.getObserableId('lab/' + this.lab + '/covid', 'config').subscribe(dto => {
            this.data = dto;
            if (dto) {
                this.hasFirstDataShown = true
                this.settingDto = new CovidSetting(dto);
                this.serverProduct = new CovidSetting(dto);
                this.pageType = 'edit';
            } else {
                this.hasFirstDataShown = true
                this.pageType = 'new';
                this.settingDto = new CovidSetting();
            }

            this.formGroup = this.createForm();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    createForm(): FormGroup {

        return this._formBuilder.group({
            staffAtHomeRiskResolutionNo: [this.settingDto.staffAtHomeRiskResolutionNo],
            staffAtHomeRiskResolutionYes: [this.settingDto.staffAtHomeRiskResolutionYes],
            staffInOfficeRiskResolutionNo: [this.settingDto.staffInOfficeRiskResolutionNo],
            staffInOfficeRiskResolutionYes: [this.settingDto.staffInOfficeRiskResolutionYes],
        });
    }

    getFormValue(): string {
        return (new CovidSetting(this.formGroup.getRawValue())).toString();
    }

    getDataValue(): string {
        return this.serverProduct.toString();
    }

    saveDocument(): void {
        this.hasFirstDataShown = false;

        this.firebaseUtilService.saveDocument('lab/' + this.lab + '/covid', 'config', this.formGroup.getRawValue()).then(value => {
            this._matSnackBar.open('Setting saved', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            this.hasFirstDataShown = true;
        });
    }

    getEmailHint() {
        return 'You can use {patientName}, {staffName}, {labName}, {labLogo}, {labNameClickable}, {clickHereUrl}, {fullUrl}';
    }

}