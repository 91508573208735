import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PatientComponent} from './list/patient.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {PatientService} from './patient.service';
import {AddPatientDialogComponent} from './addpatient/add-patient-dialog.component';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import { EditPatientDialogComponent } from './editpatient/edit-patient-dialog.component';
import {ShareAssessmentDialogComponent} from './shareAssessmentLink/share-assessment-dialog.component';
import { PatientdetailComponent } from './patientdetail/patientdetail.component';
import {SignaturePadModule} from '@ng-plus/signature-pad';
import {PatientListService} from "./list/patient-list.service";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PatientInfiniteScroll } from './list/patient-infinite-scroll.service';
import { ConformationDialogComponent } from './shareAssessmentLink/conformation-dialog/conformation-dialog.component';
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
// import {SignaturePadModule} from 'angular2-signaturepad';

const routes = [
    {
        path     : 'patient',
        component: PatientComponent,
        canActivate: [AuthGuard],
        resolve:{
            navType:PatientListService
        }
    },
    {
        path     : 'patientdetail',
        component: PatientdetailComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        PatientComponent,
        AddPatientDialogComponent,
        ShareAssessmentDialogComponent,
        EditPatientDialogComponent,
        PatientdetailComponent,
        ConformationDialogComponent,

    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        SignaturePadModule,
        MatDatepickerModule,
        MatNativeDateModule,

        // NgxMaterialTimepickerModule

    ],
    entryComponents: [
        AddPatientDialogComponent,
        ShareAssessmentDialogComponent,
    ],
    exports     : [
        PatientComponent,
        AddPatientDialogComponent,
        ShareAssessmentDialogComponent,

    ],
    providers   : [
        PatientInfiniteScroll,
        PatientService,
        PatientListService
]
})

export class PatientModule
{
}
