import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';
import * as firebase from "firebase";


export class UserDto {
    center: string;
    lab: string;
    name: string;
    userImage:string;
    role: string;
    staticData: string;
    labs: any = [];
    labsObject: any = [];

}

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    userData: Observable<firebase.User>;
    credentialData: any;

    constructor(private angularFireAuth: AngularFireAuth,
        private afs: AngularFirestore, public router: Router, public userService:StorageService) {
        this.userData = angularFireAuth.authState;
    }

    // Send email verification when new user sign up

    reSendVerificationMail() {

        return this.angularFireAuth.auth.currentUser.sendEmailVerification()
            .then(() => {
                localStorage.setItem('resendBtn', '');
                //  localStorage.setItem('EmailVerification', 'An email sent to your email address. Please validate your email address then you can login.');
                this.router.navigate(['/login']);
            });
    }

    SendVerificationMail() {

        return this.angularFireAuth.auth.currentUser.sendEmailVerification()
            .then(() => {
                localStorage.setItem('resendBtn', 'N');
                //localStorage.setItem('EmailVerification', 'Validate Your Email ');
                this.router.navigate(['/validate']);
            });
    }
    getValidationMessage(){
                const getCollectionPath ='common/config/messages';
        return new Promise<any>((resolve, reject)=>{
        this.afs.collection(getCollectionPath).doc('registration').valueChanges().subscribe(data =>{
        console.log(data);
        resolve(data);
        })
        })
    }

    /* Sign up */
    signUp(email: string, password: string) {
        return this.angularFireAuth
            .auth
            .createUserWithEmailAndPassword(email, password)
            .then(res => {
                this.SendVerificationMail();
            })
    }




    /* Sign in */
    signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.angularFireAuth
            .auth
            .signInWithEmailAndPassword(email, password);

    }

    getUser(email): Promise<UserDto> {
        return new Promise((resolve, reject) => {
            this.afs.collection('user').doc(email).get().subscribe((doc: any) => {
                if (doc) {
                    const userInfo = doc.data();
                    resolve(userInfo);
                } else {
                    reject(new Error('sterilwize.user.not.available'));
                }
            });
        });
    }


    /* Sign out */
    signOut(): void {
        this.angularFireAuth
            .auth
            .signOut();
    }

    setCredentialData(credentialData: any) {
        this.credentialData = credentialData;
    }

    getCredentialData(): any {
        return this.credentialData;
    }

    isLoggedIn() {
        return this.angularFireAuth.authState.pipe(first()).toPromise();
    }

    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(value.email, value.password)
                .then(res => {
                    resolve(res);
                }, err => reject(err));
        });
    }

    doLogout() {
        return new Promise((resolve, reject) => {
            if (firebase.auth().currentUser) {
                this.angularFireAuth.auth.signOut();
                resolve('logout');
            } else {
                reject(new Error('No current user found'));
            }
        });
    }

    isUserLoggedIn() {
        return firebase.auth().currentUser != null;
    }

    getRoleById(roleId) {

        return new Promise<any>((resolve, reject) => {
            const collectionName = 'common/config/webRole';
            this.afs.collection(collectionName).doc(roleId).get().subscribe((doc) => {
                if (doc) {
                    resolve(doc.data());
                } else {
                    reject(' Error in reading the role details');
                }
            });
        });

    }
}
