import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
// import {ConsentService} from '../covidconsent/consent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CovidQuestionPaginationService } from '../list/covid-question-pagination.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

// export interface GroupItem {
//     name: string;
// }


@Component({
    selector: 'app-addcovidquestion',
    templateUrl: './addcovidquestion.component.html',
    styleUrls: ['./addcovidquestion.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AddcovidquestionComponent implements OnInit {

    addCovidQuestForm: FormGroup;
    selected = 'No';
    hasCheckbox = false;
    riskType = [];
    children = [];
    showGroup = false;

    // chip

    groupItems: Array<any> = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    add(event: MatChipInputEvent): void {

        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.groupItems.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item): void {
        const index = this.groupItems.indexOf(item);

        if (index >= 0) {
            this.groupItems.splice(index, 1);
        }
    }


    // chip



    constructor(private _formBuilder: FormBuilder, private covidQuestionService: CovidQuestionPaginationService,
        public snackBar: MatSnackBar,) { }

    ngOnInit(): void {

        this.addCovidQuestForm = this._formBuilder.group({
            title: ['', [Validators.required], ''],
            order: ['', [Validators.required],],
            type: ['', [Validators.required],],
            risk: ['', [Validators.required],],
            children: ['', [],],
            minAge: [0, [Validators.required, Validators.max(100), Validators.min(0)]],
            maxAge: [100, [Validators.required, Validators.max(100), Validators.min(0)]],

        });

        this.riskType = ['High', 'Probable', 'Moderate', 'Low'];

    }

    addQuestion() {

        const data =
        {
            title: this.addCovidQuestForm.value.title,
            order: this.addCovidQuestForm.value.order,
            type: this.addCovidQuestForm.value.type,
            risk: this.addCovidQuestForm.value.risk,
            minAge: this.addCovidQuestForm.value.minAge,
            maxAge: this.addCovidQuestForm.value.maxAge,
        };

        if (this.addCovidQuestForm.value.type === 'group') {
            if (this.groupItems.length < 1) {
                alert('Please add sub questions or change type to Item');
                return;
            }

            data['children'] = this.groupItems;
        }

        this.covidQuestionService.addQuestionData(data).then(resultData => {
            this.groupItems = [];
            this.addCovidQuestForm.reset();
            const msg = 'Question Added Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });

    }


    changeSelect(event) {
        if (event.value === 'group') {
            this.showGroup = true;
        } else {
            this.showGroup = false;
        }
    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }


}

