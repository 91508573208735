import { AfterViewInit, Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CassetteListService } from '../list/cassette-list.service';
import { AssignService } from '../assign/assign.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusService } from '../../status/status.service';
import { Cassette } from "../cassette";
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ApiService } from '../../../common/service/api.service';
import { Globals } from '../../../common/global';

@Component({
    selector: 'app-viewcassettedetails',
    templateUrl: './viewcassettedetails.component.html',
    styleUrls: ['./viewcassettedetails.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ViewcassettedetailsComponent implements AfterViewInit, OnInit {

    urlData = '';
    cassetteData: any;
    barcode = '';
    cycleNo = '';
    name = '';
    id = '';
    time = '';
    type = '';
    program = '';
    loadNo: number;
    machineName = '';
    status = '';
    result = ' ';
    barcodeBi: any;
    barcodeEi: any;
    barcodeBd: any;
    isAssignDone: boolean;
    statusBi: string;
    statusEi: string;
    statusBd: string;
    date = new Date();
    patientName = '';
    patientId = '';
    assignedBy = '';
    assignedOn: any;
    status1 = '';
    spoiledTime = '';
    spoiledName = '';
    addedName = '';
    addedOn = '';
    processedBy = '';
    processedOn = '';
    canAssignIncubator: boolean;

    cstObservableData: Observable<any>;

    constructor(public cassetteListService: CassetteListService,
        private statusService: StatusService,
        private globals: Globals,
        private route: ActivatedRoute, private router: Router,
        private assignService: AssignService, private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private userService: StorageService,
        public dialog: MatDialog, public apiService: ApiService) {
    }


    ngOnInit(): void {
        this.urlData = this.route.snapshot.queryParams.barcode;
    }


    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    ngAfterViewInit(): void {

        const cassetteConfig = this.globals.cassetteConfig;

        if (this.urlData !== '' && this.urlData !== undefined) {

            this.cstObservableData = this.cassetteListService.getCassetteById(this.urlData);
            this.cstObservableData.subscribe(resultData => {
                this.cassetteData = resultData[0];
                this.barcode = resultData[0].barcode;
                this.type = resultData[0].type;
                this.program = resultData[0].program;
                this.cycleNo = resultData[0].cycleNo;
                this.loadNo = resultData[0].loadNo;
                this.machineName = resultData[0].machineName;
                this.status1 = resultData[0].status; // being used as a condition in html page
                this.status = this.statusService.getCassetteStatus(resultData[0]);

                let eStatus: EStatus = this.statusService.getStatusNew(resultData[0].statusBd, resultData[0].statusBi, resultData[0].statusEi);

                this.result = this.statusService.getCassetteResult(eStatus);
                this.barcodeBd = resultData[0].barcodeBd ? resultData[0].barcodeBd : '-';
                this.barcodeBi = resultData[0].barcodeBi;
                this.barcodeEi = resultData[0].barcodeEi;
                this.statusBi = resultData[0].statusBi;
                this.statusEi = resultData[0].statusEi;
                this.statusBd = resultData[0].statusBd;
                this.isAssignDone = resultData[0].isAssignDone;
                this.canAssignIncubator = cassetteConfig.canAssignIfIncubatorPending;
                this.processedBy= resultData[0].updated.name;
                this.processedOn =this.apiService.getDateStringMomentByTz(resultData[0].updated.time) ;
            
                if (resultData[0].added) {
                    this.addedName = resultData[0].added.name;
                    this.id = resultData[0].added.id;
                    // this.addedOn = resultData[0].added.time ;
                    this.addedOn = this.apiService.getDateStringMomentByTz(resultData[0].added.time) ;
                } else if (resultData[0].startedBy) {
                    this.addedName = resultData[0].startedBy.name;
                    this.id = resultData[0].startedBy.id;
                    // this.addedOn = resultData[0].startedBy.time;
                    this.addedOn = this.apiService.getDateStringMomentByTz(resultData[0].startedBy.time);
                }
                if (resultData[0].assigned) {
                    this.name = resultData[0].assigned.name;
                    this.id = resultData[0].assigned.id;
                    this.time = resultData[0].assigned.time;
                }
                if (resultData[0].patient) {
                    this.patientId = resultData[0].patient.patientId;
                    this.patientName = resultData[0].patient.patientName;
                }
                if (resultData[0].assigned) {
                    this.assignedBy = resultData[0].assigned.name;
                    // this.assignedOn =  resultData[0].assigned.time;
                    this.assignedOn = this.apiService.getDateStringMomentByTz(resultData[0].assigned.time);
                }
                if (resultData[0].spoiled) {
                    this.spoiledName = resultData[0].spoiled.name;
                    // this.spoiledTime = resultData[0].spoiled.time;
                    this.spoiledTime = this.apiService.getDateStringMomentByTz(resultData[0].spoiled.time);
                }

            });
        } else {

            window.alert('No Data found, try again!');
        }
    }

    unAssignCassette() {
        // this.urlData
        this.assignService.updateCassette(this.urlData, {
            status: 'Ready',
            isAssignDone: false,
            assigned: null,
            dateTimeNum: -(this.date.getTime()),
            updated: {
                id: this.userService.getCookie("email"),
                name: this.userService.getCookie("name"),
                time: this.date.getTime() // timestamp
            },
            patient: {
                patientId: '',
                patientName: '',
            },
            patientId: '',
            patientName: '',

        }).then(data => {
            this.isAssignDone = false;
            this.openSnackBar('Cassette Unassigned Successfully', 'Ok');
        }).catch((error) => {
            this.openSnackBar('Server Error, Tyr Again!', 'Ok');

        });

    }

    spoilCassette() {
        this.assignService.updateCassette(this.urlData, {
            status: 'Spoiled',
            isAssignDone: false,
            isSpoiled: true,
            assigned: null,
            dateTimeNum: -(this.date.getTime()),
            spoiled: {
                id: this.userService.getCookie("email"),
                name: this.userService.getCookie("name"),
                time: this.date.getTime() // timestamp
            }
        }).then(data => {
            this.status1 = 'Spoiled';
            this.isAssignDone = false;
            this.openSnackBar('Cassette Spoiled SuccessFully', 'Ok');
        });

    }

    assignCassette(barcode) {
        this.router.navigate(['/assign'], { queryParams: { barcode: barcode } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }
    getLoadColor(load: Cassette): string {
        if (load)
            return this.statusService.getColor(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
        else
            return null;
    }

    openDialogWithRef(ref: TemplateRef<any>,): void {
        this.dialog.open(ref, { width: '400px' });
        // this.router.navigate(['/viewtool']);
    }


}
