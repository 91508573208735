import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FirebaseUtilService } from "../../../common/service/FirebaseUtil.service";
import { CovidSetting } from "./ScreeningEmailSetting.model";


@Component({
    selector: 'app-ScreeningEmailSetting',
    templateUrl: './ScreeningEmailSetting.component.html',
    styleUrls: ['./ScreeningEmailSetting.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ScreeningEmailSettingComponent implements OnInit, OnDestroy {

    private settingDto: CovidSetting;
    pageType: string;
    formGroup: FormGroup;
    data: any;
    serverProduct: CovidSetting;
    lab = FirebaseUtilService.LAB_PLACE_HOLDER;
    selected = 'No';
    selectedPatient = 'No';
    selectedLabCheckout = 'No';
    selectedHour = '1';
    mailSentArr: any;
    daysArray: any;
    sun = 'sun';
    hasFirstDataShown = false;
    // daysArrayList = ['sun','mon', 'tue','wed', 'thu','fri','sat'];

    daysArrayList: any = [{
        days: [{ value: 0, valueView: 'sun', checked: false },
        { value: 1, valueView: 'mon', checked: false },
        { value: 2, valueView: 'tue', checked: false },
        { value: 3, valueView: 'wed', checked: false },
        { value: 4, valueView: 'Thu', checked: false },
        { value: 5, valueView: 'Fri', checked: false },
        { value: 6, valueView: 'Sat', checked: false }],
    }];
    hoursArrayList: any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
       
    isDisabled = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     * @param {Location} _location
     * @param {MatSnackBar} _matSnackBar
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private firebaseUtilService: FirebaseUtilService,
    ) {
        // Set the default
        this.settingDto = new CovidSetting();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.hoursArrayList;
        // console.log('hoursArrayList = '+this.hoursArrayList)
        this.firebaseUtilService.getObserableId('lab/' + this.lab + '/covid', 'config').subscribe(dto => {
            this.data = dto;
            if (dto) {
                this.hasFirstDataShown = true
                this.settingDto = new CovidSetting(dto);
                this.serverProduct = new CovidSetting(dto);
                this.selected = this.settingDto.enableStaffEmail;
                this.selectedPatient = this.settingDto.enablePatientEmail;
                this.selectedLabCheckout = this.settingDto.enableLabCheckoutEmail;
                this.selectedHour = this.settingDto.selectedhours;
                // console.log(this.settingDto.staffMailDaysArray);
                if (this.settingDto.staffMailDaysArray.length > 0) {
                    this.daysArrayList = this.settingDto.staffMailDaysArray;
                }
                if (this.settingDto.enableStaffEmail === 'Yes') {
                    this.isDisabled = false;
                } else {
                    this.isDisabled = true;
                }
                this.pageType = 'edit';
            } else {
                this.hasFirstDataShown = true
                this.pageType = 'new';
                this.settingDto = new CovidSetting();
            }
            this.formGroup = this.createForm();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    createForm(): FormGroup {
        return this._formBuilder.group({

            staffEmailSubject: [this.settingDto.staffEmailSubject],
            staffEmailFrom: [this.settingDto.staffEmailFrom],
            staffEmailBody: [this.settingDto.staffEmailBody],
            patientEmailFrom: [this.settingDto.patientEmailFrom],
            patientEmailSubject: [this.settingDto.patientEmailSubject],
            patientEmailBody: [this.settingDto.patientEmailBody],

            patientEmailAlertFrom: [this.settingDto.patientEmailAlertFrom],
            patientEmailAlertSubject: [this.settingDto.patientEmailAlertSubject],
            patientEmailAlertBody: [this.settingDto.patientEmailAlertBody],
            patientEmailAlertOfficeBody: [this.settingDto.patientEmailAlertOfficeBody],

            enableStaffEmail: [this.settingDto.enableStaffEmail],
            enablePatientEmail: [this.settingDto.enablePatientEmail],
            enableLabCheckoutEmail: [this.settingDto.enableLabCheckoutEmail],
            selectedhours: [this.settingDto.selectedhours],
            staffMailDaysArray: [this.daysArrayList],

        });
    }

    getFormValue(): string {
        return (new CovidSetting(this.formGroup.getRawValue())).toString();
    }

    getDataValue(): string {
        return this.serverProduct.toString();
    }

    saveDocument(): void {

        this.hasFirstDataShown = false;
        this.firebaseUtilService.saveDocument('lab/' + this.lab + '/covid', 'config', this.formGroup.getRawValue()).then(value => {
            this._matSnackBar.open('Setting saved', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            this.hasFirstDataShown = true;
        });
    }

    getEmailHint() {
        return 'You can use {patientName}, {staffName}, {labName}, {labLogo}, {labNameClickable}, {clickHereUrl}, {fullUrl}, {dateTime}';
    }

    changeCheckboxEvent(event) {
        // staffMailDaysArray
        // console.log(event);
        this.changeDesc(event.source.name, event.checked);
        // console.log(this.daysArrayList);
    }


    changeDesc(value, boolVal) {
        for (let i in this.daysArrayList[0].days) {
            if (this.daysArrayList[0].days[i].valueView === value) {
                this.daysArrayList[0].days[i].checked = boolVal;
                break;
            }

        }
    }

}