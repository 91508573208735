import { InMemoryDbService } from 'angular-in-memory-web-api';
import {HomeDb} from './home';
import {FaqFakeDb} from "./faq";
import {KnowledgeBaseFakeDb} from "./knowledge-base";
import {ChatFakeDb} from "./chat";
import {AcademyFakeDb} from "./academy";



export class FakeDbService implements InMemoryDbService
{
    createDb(): any
    {
        return {
            // Dashboards
            'analytics-dashboard-widgets': HomeDb.widgets,

            // FAQ
            'faq': FaqFakeDb.data,
            // Knowledge base
            'knowledge-base': KnowledgeBaseFakeDb.data,


            // Chat
            'chat-contacts': ChatFakeDb.contacts,
            'chat-chats'   : ChatFakeDb.chats,
            'chat-user'    : ChatFakeDb.user,

            // Academy
            'academy-categories': AcademyFakeDb.categories,
            'academy-courses'   : AcademyFakeDb.courses,
            'academy-course'    : AcademyFakeDb.course,

        };
    }
}
