import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';
import {AuthGuard} from "../../auth/auth.guard";
import {PatientConsentSettingComponent} from "./patient-consent-setting/PatientConsentSetting.component";
import {SharedModule} from "../../common/shared.module";
import {PatientResolutionSettingComponent} from "./patient-resolution-setting/PatientResolutionSetting.component";
import {StaffResolutionSettingComponent} from "./staff-resolution-setting/StaffResolutionSetting.component";
import {ScreeningEmailSettingComponent} from "./screening-email-setting/ScreeningEmailSetting.component";


const routes = [
    {
        path     : 'patientConsentSetting',
        component: PatientConsentSettingComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'patientResolutionSetting',
        component: PatientResolutionSettingComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'staffResolutionSetting',
        component: StaffResolutionSettingComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'screeningEmailSetting',
        component: ScreeningEmailSettingComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    declarations: [
        PatientConsentSettingComponent,
        PatientResolutionSettingComponent,
        StaffResolutionSettingComponent,
        ScreeningEmailSettingComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatExpansionModule,
        MatIconModule,
        SharedModule,
        FuseSharedModule
    ],
    providers   : [
    ]
})
export class ScreeningSettingModule
{
}
