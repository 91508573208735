export class TextUtils {
    public static isEmpty(str: string): boolean {
        if (str && str.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public static truncate(str: string, len: number): string {
        if (str == null)
            return "";
        if (str.length > len) {
            return str.substring(0, len) + "...";
        } else {
            return str;
        }
    }


    public static getEmptyStringIfNull(value: string): string {
        if (TextUtils.isEmpty(value))
            return "";
        return value;
    }

    public static getNoIfFalse(value: boolean): string {
        if (value)
            return 'Yes';
        else
            return 'No';
    }

    public static getEmptyStringIfNullForNum(value: number): string {
        if (value == 0)
            return "";
        return "" + value;
    }

    public static removeSpace(value: string): string {
        if (value)
            return value.trim().split(' ').join('');
        else
            return ''
    }

    public static toUpperCase(value: string): string {
        return value.toUpperCase();
    }
}

