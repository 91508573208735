import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../../common/service/storage.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cassette } from '../cassette';
import { StatusService } from '../../status/status.service';
import * as moment from 'moment';
import { CassetteListService } from './cassette-list.service';
import { ConfigService } from '../../../common/config/config.service';
import { Globals } from '../../../common/global';
// import {BaseComponent} from '../../base/base.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from '../../../base/base-list.component';
const timeZone = require('moment-timezone');
import { Subscription } from 'rxjs';
import { CassetteInfiniteScroll } from './cassette-infinite-scroll.service';



@Component({
    selector: 'cassette-table',
    templateUrl: 'cassette.component.html',
    styleUrls: ['cassette.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CassetteComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewInit {


    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);

    dataSource = new MatTableDataSource<any>();

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    cassette: string;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: '',
    };
    unassignList:Array<any> = [];
    disableUnassign = true;

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isLoading = false;
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;


    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: CassetteListService,
        private infiniteScroll: CassetteInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals) {
        super(snackBar, configService, globals);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.initData();
    }


    initData() {

        if(CassetteComponent.oldData && CassetteComponent.oldData.length > 0){
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
            this.dataN = CassetteComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
            this.isLoading = false;
            this.initWithOldData()
            return
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.infiniteScroll.init('cassette', 10, 'dateTimeNum', {reverse: false, prepend: false})

        this.subscription = this.infiniteScroll.data.subscribe(data => {
            if(data){} else return
                        this.dataN = data;
            if (this.dataSource && this.dataN && this.dataSource.data.length == this.dataN.length) {
                this.isMoreDataAvailable = false;
            }
            try{
                data.forEach(value => this.updateRow(value));
            } catch (e){}

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if(this.dataN && this.dataN.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            } else {

            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 200)
        });
    }


    initWithOldData(){
        this.infiniteScroll.initWithPrev(CassetteComponent.cursor, CassetteComponent.oldData,
            'patientResult', 10, 'doa', {reverse: false, prepend: false})

        this.subscription = this.infiniteScroll.data.subscribe(data => {
            if(data){} else return

            this.dataN = data;
            if (this.dataSource && this.dataN &&
                (this.dataSource.data.length - CassetteComponent.oldData.length)  == this.dataN.length) {
                console.log('isMoreDataAvailable' + 'this.dataSource.data.length'  +this.dataSource.data.length
                + 'this.dataN.length'  +this.dataN.length)
                // this.isMoreDataAvailable = false;
            }
            data.forEach(value => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = CassetteComponent.oldData.concat(this.dataN);
            if(this.dataSource.data && this.dataSource.data.length > 0){
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000)
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000)
        });
        setTimeout(() => {
            this.scrollToBottom();
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck()
            this.changeDetectorRefs.detectChanges()
        }, 1000)
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector('mat-table');
            table.scrollBy({behavior: 'smooth', left: 0, top: table.scrollHeight});
        }

    }


    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();


    }
    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'Barcode', header: 'Barcode', cell: (element: Cassette, index: number) => `${element.barcode}` },
            { columnDef: 'Type', header: 'Type', cell: (element: Cassette, index: number) => `${element.type}` },
            { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
            { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Cassette, index: number) => `${element.cycleNo}` },
            {
                columnDef: 'Result', header: 'Result',
                cell: (element: Cassette, index: number) => `${this.getCassetteResult(element)}`,
                textColor: (element: Cassette, index: number) => `${this.getLoadColor(element)}`
            },
            { columnDef: 'Date', header: 'Date', cell: (element: Cassette, index: number) => `${element.date}` },
            { columnDef: 'Time', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },
            {
                columnDef: 'BD', header: 'BD',
                cell: (element: Cassette, index: number) => `${element.barcodeBd ? element.barcodeBd : '-'}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBd)}`
            },
            {
                columnDef: 'BI', header: 'BI(Spore Test)',
                cell: (element: Cassette, index: number) => `${element.barcodeBi ? element.barcodeBi : '-'}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBi)}`
            },
            {
                columnDef: 'EI', header: 'PCD',
                cell: (element: Cassette, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusEi)}`
            },
            {
                columnDef: 'Status', header: 'Status',
                cell: (element: Cassette, index: number) => `${this.getLoadStatus(element)}`
            },
            {
                columnDef: 'details', header: 'Details',
                cell: (element: Cassette, index: number) => this.loadStatusUsed(this.getLoadStatus(element), element.id)
            },
            {
                columnDef: 'action', header: 'Action',
                cell: (element: Cassette, index: number) => this.canAssign(element)
            },
            // {
            //     columnDef: 'unassign', header: 'UnAssign',
            //     cell: (element: Cassette, index: number) => this.loadStatusUnassign(this.getLoadStatus(element), element.id)
            // },

        ];
    }

    loadStatusAvailable(functValue, barcode) {
        if (functValue === 'Availbale') {
            return barcode;
        }
    }
    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'){
        return id;
        // }
    }
    loadStatusUnassign(functValue, id) {
        if(functValue === 'USED' || functValue === 'Used' || functValue === 'Used(P)' ){
                 return id;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }
    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.time = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
            // row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    getTitleMatIcon(): string {
        return 'ballot';
    }

    getTitle(): string {
        return 'Cassettes';
    }

    getSearchText(): string {
        return 'Search cassette via barcode';
    }

    assignCassette(barcode) {
        this.router.navigate(['/assign'], { queryParams: { barcode: barcode.trim() } });
    }
    viewCassette(barcode) {
        this.router.navigate(['/viewcassettedetails'], { queryParams: { barcode: barcode } });

    }

    protected getPaginationService() {
        return this.paginationService;
    }
    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }

    changeCheckboxEvent(event, row){

        if(event.cheked === true){
            this.unassignList.push(row);
            console.log(this.unassignList);
        }else{
            this.unassignList = this.unassignList.filter(element => element !== row);
            console.log(this.unassignList);
        }
    }


    nextPage(): void {

        this.isLoading = true;
        this.isNextPressed = true;
        this.infiniteScroll.more();
    }


    ngOnDestroy(): void {
        // console.log('destroyed cassette');

        this.subscription.unsubscribe();
        CassetteComponent.oldData = this.dataSource.data
        CassetteComponent.cursor = this.infiniteScroll.getCursor()
    }

}
