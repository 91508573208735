import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {FirebaseUtilService} from '../../../../common/service/FirebaseUtil.service';

@Injectable({providedIn:'root'})
export class SelfAssessmentQuestionsService implements Resolve<any> {
    routeParams: any;
    data: any;
    onDataChanged: BehaviorSubject<any>;

    constructor(
        public firebaseUtil: FirebaseUtilService
    ) {
        // Set the defaults
        this.onDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getData(): Promise<any> {


        return new Promise((resolve, reject) => {
            this.firebaseUtil.getDocuments(`/lab/${FirebaseUtilService.LAB_PLACE_HOLDER}/covid/constant/patientQuestion`)
                .subscribe((documents: any[]) => {
                    // const questions = this.getQuestionObjFromDocuments(documents);
                    // const result = {
                    //     questionArray: documents,
                    //     questionObj: questions
                    // };
                    this.data = documents;
                    this.onDataChanged.next(this.data);
                    resolve(documents);
                }, reject);
        });
    }

    getQuestionObjFromDocuments(documents: any[]) :any {

        
        const questions = {};
        for (let i = 0; i < documents.length; i++) {
            const questionObj = documents[i];
            const id = questionObj.id;
            const prevId = (i == 0) ? 'startAssessment' : documents[i - 1].id;
            const nextId = (i == documents.length - 1) ? 'r7' : documents[i + 1].id;
            console.log(questionObj);
        
                const question = {
                    id: questionObj.id,
                    questionNo: '' + (i +1 ),
                    questionId: id,
                    type: 'yesNo',
                    question: questionObj.title,
                    description: questionObj.description,
                    order: questionObj.order,
                    parent: questionObj.parent,
                    buttons: [
                        {
                            label: 'no',
                            cta: nextId,
                        },
                        {
                            label: 'yes',
                            cta: nextId,
                        },
                    ],
                    preAppointSelection: false,
                    inOfficeSelection: false,
                    risk: this.getRiskLevel(questionObj.risk),
                    backButtonCta: prevId
                };

                console.log("questionmmmmm.." + question);
                // adding children if question have
                console.log()
            if(questionObj.children){
                
                question['questionItems'] = questionObj.children;
            }

            questions[id] = question;
          
        }
        return questions;
    }

    getRiskLevel(risk: string) {
        switch (risk) {
            case 'High':
                return 'high';
            case 'Probable':
                return 'medium';
            case 'Moderate':
                return 'medium';
            case 'Low':
                return 'low';

        }
    }



}
