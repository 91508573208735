import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {
    MatSnackBar, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {CookieService} from 'ngx-cookie-service';
import {StorageService} from '../common/service/storage.service';
import {ApiService} from '../common/service/api.service';
import {Globals} from '../common/global';
import {Role} from '../common/role';


@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(private router: Router, private snackBar: MatSnackBar,
                private userService: StorageService,
                private cookieService: CookieService, private apiService: ApiService,
                private globals: Globals, private role: Role) {
    }

    // authRole = ['support','admin'];
    // stateUrl = ['/labelhistory'];

    currentRole = this.userService.getCookie('role');
    currentLab = this.userService.getCookie('lab');
    // currentNavType = this.userService.getCookie('navigationType');
    navigationBoolenVal = this.userService.getCookie('pageDynamicConfig');


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (this.userService.getCookie('email') != null) {

            // console.log(state.url.replace('/',''));
            // console.log(JSON.parse(this.navigationBoolenVal));
            // console.log(JSON.parse(this.navigationBoolenVal)[state.url.replace('/','')]);
            // if(JSON.parse(this.navigationBoolenVal)[state.url.replace('/','')] === false){
            //     this.router.navigate(['/login']);
            //         return false;
            // }
            // if (this.role.stateUrl.includes(state.url) && !this.role.authRole.includes(this.globals.teamMemberConfig)) {
            //     this.router.navigate(['/home']);
            //     return false;
            //     }
            // console.log('labName',this.currentLab);
            // this.apiService.getRoleAndPermission(this.currentLab).then(async ( data:any) => {
            //     const dataRole = await data.data.role;
            //     console.log(dataRole)
            //         if (this.stateUrl.includes(state.url) && !this.authRole.includes(dataRole)) {
            //             this.router.navigate(['/home']);
            //             return false;
            //         }
            // })
            // if ( this.cookieService.get('email') != null) {

            
            // if (this.currentLab) {
            //   this.openSnackBar('You are not authorized to access this section!', 'OK');
            //   this.router.navigate(['/load']);
            //   return false;
            // }

            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 5000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }


}


