import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { RegisterComponent } from 'app/main/module/auth/register/register.component';
import {SharedModule} from '../../common/shared.module';

const routes = [
    {
        path     : 'register',
        component: RegisterComponent
    }
];

@NgModule({
    declarations: [
        RegisterComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        SharedModule,
        FuseSharedModule
    ]
})
export class RegisterModule
{
}
