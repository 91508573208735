import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {SignaturePadModule} from '@ng-plus/signature-pad';
// import {CovidStaffservice} from '../covid/covid.service';


import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {StaffassessmentComponent} from "./staffassessment/staffassessment.component";
import {CovidstaffresultlistComponent} from "./covidstaffresultlist/covidstaffresultlist.component";
import {AuthGuard} from "../../auth/auth.guard";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {FuseSharedModule} from "../../../../../@fuse/shared.module";
import {SharedModule} from "../../common/shared.module";
import {AsapMaterialModule} from "../../../../material-module";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {AgmCoreModule} from "@agm/core";
import {FuseWidgetModule} from "../../../../../@fuse/components";
import {StaffconfirmcardComponent} from "./staffconfirmcard/staffconfirmcard.component";
import {StaffquestionComponent} from "./staffquestion/staffquestion.component";
import {CovidstaffService} from "./covidstaff.service";
import {CovidStaffResultInfiniteScroll} from './covidstaffresultlist/covidstaffressult-infinite-scrol.service';
import { StaffinitComponent } from './staffinit/staffinit.component';



const routes = [
    {
        path: 'staffassessment',
        component: StaffassessmentComponent,
        // canActivate: [AuthGuard],
    },
    {
        path: 'covidstaffresultlist',
        component: CovidstaffresultlistComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'staffconfirmcard',
        component: StaffconfirmcardComponent,
        // canActivate: [AuthGuard],
    },
    {
        path: 'staffinit',
        component: StaffinitComponent,
        // canActivate: [AuthGuard],
    },
    {
        path: 'staffquestion',
        component: StaffquestionComponent,
        canActivate: [AuthGuard],
    },

]


@NgModule({
    declarations: [CovidstaffresultlistComponent, StaffassessmentComponent, StaffconfirmcardComponent, StaffquestionComponent, StaffinitComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,
        SignaturePadModule



    ],
    exports:[
        CovidstaffresultlistComponent,
        StaffassessmentComponent,
        StaffconfirmcardComponent,
        StaffquestionComponent,
        StaffinitComponent,

    ],
    providers:[
        CovidstaffService,AngularFirestore,AngularFireStorage,CovidStaffResultInfiniteScroll
    ],
})
export class StaffScreeningModule
{

}
