import {AfterViewInit, Component, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {LabelService} from '../label.service';
import { ApiService} from '../../../common/service/api.service';

@Component({
    selector: 'app-labeldetail',
    templateUrl: './labeldetail.component.html',
    styleUrls: ['./labeldetail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LabeldetailComponent implements OnInit, AfterViewInit {

    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['barcode', 'cassettetype', 'status'];
    urlData = '';
    loadObservableData: Observable<any>;
    cstObservableData: Observable<any>;

    isLoading: boolean;

    currentCount: number;
    reminderCount: number;
    minimumCount: number;
    lastPrintFrom: string;
    lastPrintTo: string;
    lastPrintOn:string;
    lastPrintBy: string;
    leadDays = 0;
    lastUsed:string;
    lastUsedOn:string;



    constructor(

        public labelService: LabelService,
        private route: ActivatedRoute, private router: Router,
        public statusService: StatusService,
        private location: Location, public dialog: MatDialog, public apiService: ApiService,
    ) {

        this.isLoading = true;
    }

    ngOnInit(): void {
        this.urlData = this.route.snapshot.queryParams.barcode;
    }


    ngAfterViewInit(): void {

        const axc = this.labelService.getLabelById(this.urlData).then((labelData: any) =>{
            console.log(labelData);
            this.currentCount = labelData.currentCount;
            this.lastPrintBy = labelData.lastPrintBy;
            this.lastPrintFrom = labelData.lastPrintFrom;
            this.lastPrintOn = this.apiService.getDateStringMomentByTz(labelData.lastPrintOn);
            this.lastPrintTo = labelData.lastPrintTo;
            this.lastUsed = labelData.lastUsed;
            this.lastUsedOn = this.apiService.getDateStringMomentByTz(labelData.lastUsedOn);
            this.minimumCount = labelData.minimumCount;
            this.reminderCount = labelData.reminderCount;
        })



        // this.loadObservableData.subscribe(resultData => {
        //
        //     this.loadDetailsData = resultData[0];
        //     this.barcode = resultData[0].barcode;
        //     this.program = resultData[0].program;
        //     this.loadNo = resultData[0].loadNo;
        //     // this.status = resultData[0].status;
        //     this.machineName = resultData[0].machineName;
        //     this.cycleNo = resultData[0].cycleNo;
        //     this.aiImageUrl = resultData[0].aiImageUrl;
        //     this.barcodeBi = resultData[0].barcodeBi;
        //     this.barcodeEi = resultData[0].barcodeEi;
        //     this.machineReportUrl = resultData[0].machineReportUrl;
        //
        //     if (resultData[0].startedBy) {
        //
        //         this.startedName = resultData[0].startedBy.name;
        //         this.startedTime = resultData[0].startedBy.time;
        //     }
        //     if (resultData[0].updatedBy) {
        //         this.endedName = resultData[0].updatedBy.name;
        //         this.endedTime = resultData[0].updatedBy.time;
        //     }
        //
        //
        // });

    }


}

