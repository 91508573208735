import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { BaseListService } from "../../../base/base-list.service";
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable()
export class LoadListService extends BaseListService {
    protected isOrderByNegative = true;
    protected searchBy = 'barcode';
    constructor(private afsChild: AngularFirestore, private userServiceChild: StorageService) {
        super(afsChild, userServiceChild);
        this.table = 'load';
        this.orderBy = 'startTimeNum';
    }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }

    getCollectionPath(): string {
        return 'lab/' + this.userServiceChild.getCookie("lab") + '/' + this.table;
    }

    getSearchQueryWithOutText(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getSearchQueryWithText(): any {

        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .where(this.searchBy, '==', this.searchedText)
            .limit(100));
    }


    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .startAfter(this.startingEntry)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }


    getCountLoadData(){
        this.getCollection(this.getCollectionPath()).subscribe(countData =>{
            console.log(countData.length);
        })
    }
}
