export class LogUtil {
    public static log(tag: string, obj: any) {
        try {

            //do something 
        } catch (e) {
        }
        try {
            //do something 
        } catch (e) {
        }
        try {
            //do something 
        } catch (e) {
        }

    }
}