import { Component, OnInit, ViewEncapsulation, Directive, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '../../common/service/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { TeamService } from '../../sterilwize/team/team.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from '../../common/global';
import { ConfigService } from '../../common/config/config.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ApiService } from '../../common/service/api.service';
import {allTrue, covidTrue, screening, resourceCenter} from '../../../../navigation/navigationItemObjects';
import {hasOwnProperty} from 'tslint/lib/utils';
import {getLabCenterList} from '../../../../../../functions/src/api/center/centerList';

@Component({
    selector: 'login-2',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    isShowProgress = false;
    email = '';
    infoMsg = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    spinner = false;
    userData: any;
    credentialData: any;
    landingPage= 'home';
    navigationData: any;
    navigationType: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    //
    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }



    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthenticationService,
        public snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private router: Router,
        public angularFireAuth: AngularFireAuth,
        public afs: AngularFirestore,
        public teamService: TeamService,
        private ngxSpinnerService: NgxSpinnerService,
        private globals: Globals,
        private configService: ConfigService,
        private cookieService: CookieService,
        private userService: StorageService,
        private apiService: ApiService,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {



        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        this.checkIfAlreadyLogin();
    }

    async checkIfAlreadyLogin() {

        const user = await this.authService.isLoggedIn();
        console.log('aleady login user' + user);
        if (user) {
            this.checkFetchedUser(user);
            // do something
        } else {
            // do something else
        }
    }

    getClick(event) {
        // do something
    }


    login(): void {

        this.spinner = true;
        this.loginDirect(this.loginForm.value['email'], this.loginForm.value['password']);

    }

    //  SOC adding labs Object Data the user who has already signed up and not having this object
    addLabsObject(teamData) {
        const labsObject = [];
        const labsData = {
            labId: undefined,
            labName: undefined,
            role: undefined
        };
        labsData.labId = teamData.lab;
        labsData.labName = teamData.lab;
        if (teamData.position !== undefined) {
            labsData.role = teamData.position;
        } else {
            labsData.role = teamData.role;
        }

        labsObject.push(labsData);
        return labsObject;

    }
    // EOC
    checkFetchedUser(result) {
        // to ask
        // console.log(result.user);
        // console.log(result.hasOwnProperty('user'));
        if(result.hasOwnProperty('user')){
            this.email = result.user.email; // setting session storage value
            this.userService.setCookie("email", this.email);
        }

        // localStorage.setItem('email', this.email);
        // this.cookieService.set('email', this.email);

        // console.log('checkResult',result);
        if (result) {
            // console.log('checkResult has value' + result);
            // firebase.auth().currentUser.emailVerified
            if (true) {
                // if (true) {
                this.credentialData = result.credential;
                this.authService.setCredentialData(this.credentialData);
                // localStorage.setItem('accessToken', this.credentialData.accessToken);
                this.userData = result.user;
                if(this.email)
                this.authService.getUser(this.email).then(async (userDto) => {
                    if (userDto && userDto.lab) {
                        localStorage.setItem('lab', userDto.lab);
                        this.userService.setCookie("lab", userDto.lab);
                        localStorage.setItem('center', userDto.center);
                        this.userService.setCookie("center", userDto.center);
                        localStorage.setItem('role', userDto.role);
                        this.userService.setCookie("role", userDto.role);
                        this.userService.setCookie("name", userDto.name);
                        this.userService.setCookie("userImage", userDto.userImage);
                        localStorage.setItem('name', userDto.name);
                        localStorage.setItem('staticData', 'N');
                        localStorage.setItem('labs', JSON.stringify(userDto.labs));
                        localStorage.setItem('labsObject', JSON.stringify(userDto.labsObject));
                        this.configService.getCassetteConfig('cassette').valueChanges().subscribe((cassetteConfig: any) => {
                            // console.log('getCassetteConfig' + cassetteConfig);
                            this.globals.cassetteConfig = cassetteConfig;
                            // this.router.navigate(['/home']);
                        });

                        // if(userDto.center){
                        //     this.userService.setCookie("center", userDto.center);
                        //     }else{
                        //     this.apiService.getLabid().then(result=>{
                        //     this.userService.setCookie("center",result.centres[0].name);
                        //     })
                        // }

                        //
                        // this.apiService.getPageConfigPermission(userDto.lab).then(async (data: any) => {
                        //     if (data && data.data) {
                        //         this.userService.setCookie('pageDynamicConfig', JSON.stringify(data.data));
                        //         console.log('data testig here ', data.data)
                        //     }
                        //     // this.globals.teamMemberConfig = await data.data.role;
                        // })

                        this.apiService.getNavigationSetting(userDto.lab).then(async (data: any) => {
                            // console.log('Setting Data',data);
                            // console.log(allTrue);
                            this.navigationType = await data.data.type;
                            switch (this.navigationType) {
                                case 'screening':
                                    this.navigationData = screening;
                                    this.landingPage = 'covid';
                                    break;
                                case 'rcenter':
                                    this.navigationData = resourceCenter;
                                    this.landingPage = 'rcenter/centers';
                                    break;
                                case 'all':
                                    this.navigationData = allTrue;
                                    this.landingPage = 'home';
                                    break;
                                default:
                                    this.navigationData = allTrue;
                                    this.landingPage = 'home';
                            }

                            if(data.data.hasOwnProperty('defaultTimeZone')){
                                this.userService.setCookie('defaultTz', data.data.defaultTimeZone);
                            }else{
                                this.userService.setCookie('defaultTz', this.globals.getDefaultTimeZone());
                            }

                            this.userService.setCookie('navigationType', data.data.type);
                            this.userService.setCookie('pageDynamicConfig', JSON.stringify(this.navigationData));
                            // this.landingPage = data.data.landingPage?data.data.landingPage:'home';
                            // this.router.navigate(['/'+this.landingPage]);
                        }).then(()=>{

                            this.router.navigate(['/'+this.landingPage]);
                        })
                    }
                    else {
                        // TODO redirect to like validate: No lab found
                        localStorage.setItem('resendBtn', '');
                        this.msgColor = this.color[1];
                        localStorage.setItem('EmailVerification', 'No lab details found !')
                        this.router.navigate(['/validate']);
                    }
                });
            } else {

                localStorage.setItem('EmailVerification', 'Validate Your Email !')
                localStorage.setItem('resendBtn', 'Y');
                this.router.navigate(['/validate']);
            }

        } else {
            console.log('checkResult not has value');
            this.msgColor = this.color[1];
            this.infoMsg = 'User not authorized';
            // alert('User not authorized');
        }
    }

    loginDirect(email: string, pass: string): void {
        this.isShowProgress = false;
        this.authService.signIn(email, pass).then((user) => {
            this.checkFetchedUser(user);
        })
            .catch((error) => {
                this.handleError(error);
                this.isShowProgress = false;
                this.spinner = false;
            });
    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    loginWithGoogle() {
        console.log('onClick loginWithGoogle');
        const provider = new firebase.auth.GoogleAuthProvider();
        this.angularFireAuth.auth.signInWithPopup(provider).then(user => {
            this.checkFetchedUser(user);
            console.log('onCheck checkFetchedUser');
        }).catch(error => {
            console.log(error);
            this.openSnackBar(error, 'OK');
            // // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.email;
            // // The firebase.auth.AuthCredential type that was used.
            // const credential = error.credential;

        });
    }

}
