import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import 'hammerjs';
import { APP_ROUTES } from 'app/app.routes';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/module/sterilwize/home/home.module';
import { PatientModule } from 'app/main/module/sterilwize/patient/patient.module';
import { AlertModule } from 'app/main/module/sterilwize/alert/alert.module';
import { LabelModule } from 'app/main/module/sterilwize/label/label.module';
import { LoginModule } from 'app/main/module/auth/login/login.module';
import { RegisterModule } from 'app/main/module/auth/register/register.module';
import { ForgotPasswordModule } from 'app/main/module/auth/forgot-password/forgot-password.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AuthenticationService } from './main/module/common/service/authentication.service';
import { AuthGuard } from './main/module/auth/auth.guard';

import { RangesFooterComponent } from './ranges-footer/ranges-footer.component';
import { AsapMaterialModule } from './material-module';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from './main/module/common/service/storage.service';
import { AnalyticsService } from './main/module/sterilwize/home/analytics.service';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ReactiveFormsModule } from '@angular/forms';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CassetteModule } from './main/module/sterilwize/cassette/cassette.module';
import { LoadModule } from './main/module/sterilwize/load/load.module';
import { TeamModule } from './main/module/sterilwize/team/team.module';
import { ReportModule } from './main/module/sterilwize/report/report.module';
import { DashboardModule } from './main/module/sterilwize/dashboard/dashboard.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MaintenanceComponent } from './main/module/auth/maintenance/maintenance.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { Globals } from './main/module/common/global';
// import {SelfAssessmentQuestionsComponent } from './main/module/covid-assessment/covid-assessment.component';
import { SelfAssessmentQuestionsComponent } from './main/module/screening/patient/self-assessment/questions/self-assessment-questions.component';
import { RcenterModule } from './main/module/support/rcenter/rcenter.module';
import { ApiService } from './main/module/common/service/api.service';
import { PiModule } from "./main/module/sterilwize/pi/pi.module";
import { SelfAssessmentQuestionsService } from "./main/module/screening/patient/self-assessment/questions/self-assessment-questions.service";
import { ChatModule } from "./main/module/support/chat/chat.module";
import { CovidService } from "./main/module/screening/covid.service";
import { ScreeningModule } from "./main/module/screening/screening.module";
import { CovidQuestionPaginationService } from "./main/module/screening/setting/questions/list/covid-question-pagination.service";
import { ReportService } from "./main/module/sterilwize/report/report.service";
import { EmailModule } from "./main/module/sterilwize/email/Email.module";
import { SuccessfulComponent } from "./main/module/sterilwize/patient/successful/successful.component";
import { TeamComponent } from "./main/module/sterilwize/team/team.component";
import { SharedModule } from "./main/module/common/shared.module";
import { ScrollableDirective } from "./main/module/common/pagination/scrollable.directive";
import { LoadingSpinnerComponent } from "./main/module/common/pagination/loading-spinner/loading-spinner.component";
import { PaginationService } from "./main/module/common/pagination/pagination.service";
// import { ScrollContainerComponent } from "./main/module/common/pagination/scroll-container/scroll-container.component";
import { CommonModule } from '@angular/common';
import { LogsModule } from './main/module/sterilwize/logs/logs.module';
import { LabModule} from './main/module/sterilwize/lab/lab.module';

@NgModule({
    declarations: [
        AppComponent,
        RangesFooterComponent,
        MaintenanceComponent,
        SelfAssessmentQuestionsComponent,
        SuccessfulComponent,
        ScrollableDirective,
        LoadingSpinnerComponent,
       

    ],
    imports: [
        AngularFireAuthModule, 
        AngularFireModule.initializeApp(environment.firebaseConfig),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(APP_ROUTES),
        AsapMaterialModule,
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        NgxDaterangepickerMd.forRoot(),
        AngularFireFunctionsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatTableModule,
        MatGridListModule,
        NgxMatSelectSearchModule,
        MatDatepickerModule,
        MatNativeDateModule, MatFormFieldModule, MatInputModule,
        MatButtonModule, SatDatepickerModule, SatNativeDateModule,
        NgxMatDrpModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FlexLayoutModule,
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
        PatientModule,
        AlertModule,
        LabelModule,
        RcenterModule,
        PiModule,
        LoginModule,
        RegisterModule,
        ForgotPasswordModule,
        CassetteModule,
        LoadModule,
        LogsModule,
        TeamModule,
        ReportModule,
        DashboardModule,
        ChatModule,
        ScreeningModule,
        EmailModule,
        CommonModule,
        LabModule,


    ],

    entryComponents: [
        SuccessfulComponent,
    ],
    exports: [
        SuccessfulComponent,
    ],
    providers: [AuthenticationService, ApiService, AuthGuard, AngularFirestore,
        StorageService, Globals, SelfAssessmentQuestionsService, CovidService, CovidQuestionPaginationService,
        AnalyticsService, PaginationService, ReportService, { provide: FunctionsRegionToken, useValue: 'us-central1' }],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
