import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { Globals} from '../../../common/global';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { LabelprefixService} from './labelprefix.service';
import { Labelprefix} from './labelprefix';


@Component({
    selector: 'app-labelprefix',
    templateUrl: './labelprefix.component.html',
    styleUrls: ['./labelprefix.component.scss',],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LabelprefixComponent implements OnInit, OnDestroy, AfterViewInit {
    addconsentForm: FormGroup;
    editconsentForm: FormGroup;
    hasCheckbox = false;
    docId = '';

    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: true,
        text: 'Add Prefix',
    };
    urlData = '';
    isLoading = true;
    hasFirstDataShown = false;
    checked = '';
    order: number;
    showGroup = false;

    cstObservableData: Observable<any>;
    // chip

    groupItems: Array<any> = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    add(event: MatChipInputEvent): void {

        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            this.groupItems.push(value.trim());
        }



        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item): void {
        const index = this.groupItems.indexOf(item);

        if (index >= 0) {
            this.groupItems.splice(index, 1);
        }
    }

    // chip


    constructor(public paginationService: LabelprefixService,
                private changeDetectorRefs: ChangeDetectorRef,
                private route: ActivatedRoute, private router: Router, private global: Globals,
                public snackBar: MatSnackBar, private _formBuilder: FormBuilder, public dialog: MatDialog,
                ) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.page = 0;
        this.urlData = this.route.snapshot.queryParams.center;
        this.addconsentForm = this._formBuilder.group({
            option: ['', [Validators.required], ''],
            sortBy: ['', [Validators.required],],
            hasCheckbox: ['', [Validators.required],],
            type: ['', [Validators.required],],
        });

        this.editconsentForm = this._formBuilder.group({
            option: ['', [Validators.required], ''],
            sortBy: ['', [Validators.required],],
            hasCheckbox: ['', [Validators.required],],
            type: ['', [Validators.required],],
        });
    }

    changeSelect(event) {
        if (event.value === 'yes') {
            this.showGroup = true;
        } else {
            this.groupItems.length = 0;
            this.showGroup = false;
        }
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {

            if (data && data.length > 0) {
                data.forEach(value => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {
                if (this.hasFirstDataShown) {
                    this.openSnackBar('No data available', 'OK');
                }
            }
            this.isLoading = false;
        });
    }

    updateRow(row): any {
        row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
        row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.next();
    }


    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.prev();
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.groupItems.length = 0;
        this.dialog.open(ref, { width: '520px', disableClose: true });
    }

    openDialogWithRefEdit(ref: TemplateRef<any>, docId) {

        this.docId = docId;
        this.paginationService.getLabelPrefixById(docId).then((data: any) => {
            if (data.hasCheckbox === true) {
                this.checked = 'Yes';

            } else {
                this.checked = 'No';
            }
            this.order = data.sortBy;



            this.editconsentForm.setValue({
                option: data.option,
                sortBy: this.order,
                hasCheckbox: this.checked,
                type: data.type,
            });

            if (data.type === 'yes') {
                this.showGroup = true;
                this.groupItems = data.children;
            } else {
                this.groupItems.length = 0;
                this.showGroup = false;
            }

        });

        this.dialog.open(ref, { width: '480px', disableClose: true });
    }


    // SOC add / edit conssent



    // addConsent() {
    //
    //     if (this.addconsentForm.value.hasCheckbox === 'Yes') {
    //         this.hasCheckbox = true;
    //     } else {
    //         this.hasCheckbox = false;
    //     }
    //
    //
    //
    //     const consentData =
    //         {
    //             option: this.addconsentForm.value.option.trim(),
    //             hasCheckbox: this.hasCheckbox,
    //             sortBy: parseInt(this.addconsentForm.value.sortBy.trim(), 10),
    //             type: this.addconsentForm.value.type,
    //         };
    //     if (this.addconsentForm.value.type === 'yes') {
    //         if (this.groupItems.length < 1) {
    //             alert('Please add sub consent or change type to No');
    //             return;
    //         }
    //
    //         consentData['children'] = this.groupItems;
    //     }
    //     const returnData = this.consentService.addConsentData(consentData);
    //     returnData.then((data: any) => {
    //         const msg = 'Consent Added Successfully';
    //         this.openSnackBar(msg, 'OK');
    //     }).catch((error) => {
    //         this.handleError(error);
    //     });
    //     this.addconsentForm.reset();
    //
    // }

    // EOC

    onCancelFrm() {
        this.addconsentForm.reset();
    }



    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: Labelprefix, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'Title', header: 'Title', cell: (element: Labelprefix, index: number) => `${element.prefix}` },
            { columnDef: 'Edit', header: 'Edit', cell: (element: Labelprefix, index: number) => `${element.id}` },
            { columnDef: 'Action', header: 'Action', cell: (element: Labelprefix, index: number) => `${element.id}` },

        ];
    }

    getTableName(): string {
        return 'config';
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Label Prefix';
    }

    getOrderByColumn(): string {
        return 'sortBy';
    }


    openDetail(row) {
        window.open(row.link, '_blank');
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    deleteConsent(docId) {
        this.paginationService.deleteLabelPrefixById(docId).then(result => {
            const msg = 'Consent Deleted Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });

    }


    updateConsent(docId) {

        if (this.editconsentForm.value.hasCheckbox === 'Yes') {
            this.hasCheckbox = true;
        } else {
            this.hasCheckbox = false;
        }
        const updateData =
            {
                option: this.editconsentForm.value.option,
                hasCheckbox: this.hasCheckbox,
                sortBy: parseInt(this.editconsentForm.value.sortBy, 10),
                type: this.editconsentForm.value.type,
            };

        if (this.editconsentForm.value.type === 'yes') {
            if (this.groupItems.length < 1) {
                alert('Please add sub consent or change type to No');
                return;
            }
            updateData['children'] = this.groupItems;
        } else {
            delete updateData['children'];
        }

        this.paginationService.updateLabelPrefix(docId, updateData).then(updateResult => {

        }).catch((error) => {
            this.handleError(error);
        });
    }
    openDialogWithRefDel(ref: TemplateRef<any>): void {



        this.dialog.open(ref, { width: '400px' });


        // this.router.navigate(['/viewtool']);
    }
}


