import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray, ValidatorFn} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';

import {FuseTranslationLoaderService} from '../../../../../../@fuse/services/translation-loader.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from "@angular/material/table";

type UserFields = 'biBarcode';
type FormErrors = {[u in UserFields]: string };
export interface PeriodicElement {
    position: number;
    type: string;
    cassetteBarcode: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {position: 1, type: 'Bi', cassetteBarcode: 1},
    {position: 2, type: 'Ei', cassetteBarcode: 4},
    {position: 3, type: 'Bi', cassetteBarcode: 6},
    {position: 4, type: 'Ei', cassetteBarcode: 9},

];





@Component({
    selector: 'anms-addload',
    templateUrl: './addload.component.html',
    styleUrls: ['./addload.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})

export class AddLoadComponent implements OnInit {


    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    biFormGroup: FormGroup;
    eiFormGroup: FormGroup;
    testBarcode: string;
    showUniqueError = false;
    showUniqueErrorEi = false;

    formErrors: FormErrors = {
        biBarcode: ''
    };
    addloadForm = this.formBuilder.group({
        Bibarcode: [''],
        Eibarcode: [''],
        Loadno: [''],
        Cycleno: [''],
        programtype: [''],
        cassettetype: [''],
        Cassettebarcode: [''],

    });

    types = [
        { name: 'Lab1', abb: 'Lab1' },
        { name: 'Lab2', abb: 'Lab2' },
        { name: 'Lab3', abb: 'Lab3' },
        { name: 'Lab4', abb: 'Lab4' },
        { name: 'Lab5', abb: 'Lab5' },
    ];
    states = [
        { name: 'Bi', abbr: 'Bi' },
        { name: 'Ei', abbr: 'Ei' },
    ];
    validationMessages = {
        biBarcode: {
            required: 'BI Barcode required',
            maxlength: 'Length of the barcode should be 10',
            pattern: 'Enter a valid BI barcode'
        },
        eiBarcode: {
            required: 'BI Barcode required',
            maxlength: 'Length of the barcode should be 10',
            pattern: 'Enter a valid BI barcode'
        }
    };
    urlData: any;
    value: any;
    values: any;



    displayedColumns: string[] = ['position', 'type', 'cassetteBarcode'];
    dataSource: MatTableDataSource<PeriodicElement>;


    cassettetype: string;
    cassettebarcode: number;
    modify: boolean;
    // tslint:disable-next-line:max-line-length
    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService, private formBuilder: FormBuilder, public dialog: MatDialog, public route: Router, public activatedRoute: ActivatedRoute) {

        this.dataSource = new MatTableDataSource(ELEMENT_DATA);



        this.firstFormGroup = this.formBuilder.group({
            cassetteValidator: ['', [Validators.required, Validators.min(1)]
            ]
        });
        this.secondFormGroup = this.formBuilder.group({
            programValidator: ['', [
                Validators.required,
                Validators.min(0)
            ]
            ]
        });
        this.thirdFormGroup = this.formBuilder.group({
            thirdCtrl1: ['', Validators.required],
            thirdCtrl2: ['', Validators.required]
        });
        this.biFormGroup = this.formBuilder.group({
            biBarcode: ['', [
                Validators.required,
                Validators.maxLength(10),
                Validators.pattern(/^BI\s*\d[\d\s]*$/)]
            ]
        });
        this.eiFormGroup = this.formBuilder.group({
            eiBarcode: ['', [
                Validators.required,
                Validators.maxLength(10),
                Validators.pattern(/^EI\s*\d[\d\s]*$/)]
            ]
        });
    }

    ngOnInit() {
        this.firstFormGroup = this.formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this.formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
    }

    AddCassette(cassettetype, cassettebarcode) {
        const obj: any = {};
        obj.name = cassettetype.name;
        obj.cassettebarcode = cassettebarcode;
        ELEMENT_DATA.push(obj);
        // reassigning data in table
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    }


}
