import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
    selector: 'forgot-password-2',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    mailSent = false;
    msgColor = '';
    color = ['green', 'red', 'blue'];
    msg = '';
    spinner = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public afs: AngularFirestore,
        public angularFireAuth: AngularFireAuth,
        public router: Router,
        public snackBar: MatSnackBar,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.mailSent = false;
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]]
        });
    }


    recoverPassword() {
        this.spinner = true;
        const emailAddress = this.forgotPasswordForm.value.email;
        this.angularFireAuth.auth.sendPasswordResetEmail(emailAddress).then(() => {
            this.msg = 'Reset Your Password';
            localStorage.setItem('recover', emailAddress);
            localStorage.setItem('EmailVerification', this.msg);
            this.msgColor = this.color[0];
            this.router.navigate(['/validate']);
        }).catch((error) => {
            // this.msg = 'Error occurred try again!';
            this.handleError(error);
            this.spinner = false;
            // An error happened.
        });
        this.spinner = false;
    }



    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }




}
