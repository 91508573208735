export class CovidSetting {
    homeHighRiskResolution: string;
    homeProbableRiskResolution: string;
    homeModerateRiskResolution: string;
    homeLowRiskResolution: string;
    homeRiskRiskResolution: string;

    inOfficeHighRiskResolution: string;
    inOfficeProbableRiskResolution: string;
    inOfficeModerateRiskResolution: string;
    inOfficeLowRiskResolution: string;
    inOfficeRiskRiskResolution: string;

    staffAtHomeRiskResolutionNo: string;
    staffAtHomeRiskResolutionYes: string;
    staffInOfficeRiskResolutionNo: string;
    staffInOfficeRiskResolutionYes: string;

    patientEmailFrom: string;
    patientEmailSubject: string;
    patientEmailBody: string;

    enableStaffEmail:string;
    enablePatientEmail:string;

    staffEmailFrom: string;
    staffEmailSubject: string;
    staffEmailBody: string;

    /**
     * Constructor
     *
     * @param product
     */
    constructor(product?) {
        product = product || {};
        this.homeHighRiskResolution = product.homeHighRiskResolution || '';
        this.homeProbableRiskResolution = product.homeProbableRiskResolution || '';
        this.homeModerateRiskResolution = product.homeModerateRiskResolution || '';
        this.homeLowRiskResolution = product.homeLowRiskResolution || '';
        this.homeRiskRiskResolution = product.homeRiskRiskResolution || '';

        this.inOfficeHighRiskResolution = product.inOfficeHighRiskResolution || '';
        this.inOfficeProbableRiskResolution = product.inOfficeProbableRiskResolution || '';
        this.inOfficeModerateRiskResolution = product.inOfficeModerateRiskResolution || '';
        this.inOfficeLowRiskResolution = product.inOfficeLowRiskResolution || '';
        this.inOfficeRiskRiskResolution = product.inOfficeRiskRiskResolution || '';

        this.staffAtHomeRiskResolutionNo = product.staffAtHomeRiskResolutionNo || '';
        this.staffAtHomeRiskResolutionYes = product.staffAtHomeRiskResolutionYes || '';
        this.staffInOfficeRiskResolutionNo = product.staffInOfficeRiskResolutionNo || '';
        this.staffInOfficeRiskResolutionYes = product.staffInOfficeRiskResolutionYes || '';


        this.enableStaffEmail = product.enableStaffEmail || '';
        this.enablePatientEmail = product.enablePatientEmail || '';

        this.patientEmailFrom = product.patientEmailFrom || '';
        this.patientEmailSubject = product.patientEmailSubject || '';
        this.patientEmailBody = product.patientEmailBody || '';

        this.staffEmailFrom = product.staffEmailFrom || '';
        this.staffEmailSubject = product.staffEmailSubject || '';
        this.staffEmailBody = product.staffEmailBody || '';

    }

    toString(): string {
        return
        this.homeHighRiskResolution +
        this.homeProbableRiskResolution +
        this.homeModerateRiskResolution +
        this.homeLowRiskResolution +
        this.homeRiskRiskResolution +

        this.inOfficeHighRiskResolution +
        this.inOfficeProbableRiskResolution +
        this.inOfficeModerateRiskResolution +
        this.inOfficeLowRiskResolution +
        this.inOfficeRiskRiskResolution +

        this.staffEmailFrom +
        this.staffEmailSubject +
        this.staffEmailBody +

        this.patientEmailFrom +
        this.patientEmailSubject +
        this.patientEmailBody


    }
}
