import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { StorageService } from '../../../common/service/storage.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Load } from '../load';
import { StatusService } from '../../status/status.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { LoadListService } from './load-list.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
const timeZone = require('moment-timezone');


@Component({
    selector: 'load-table',
    templateUrl: 'load.component.html',
    styleUrls: ['load.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LoadComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewInit {
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;


    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: LoadListService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals) {
        super(snackBar, configService, globals);
    }
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();


    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    protected extraCta = {
        visibility: false,
        text: '',
    };
    mobileViewArrHead = ['Sterilizer', 'Status', 'Cycle No', 'Load No', 'Date', 'Time', 'View'];
    mobileViewArrCell = ['machineName', 'getLoadStatus', 'cycleNo', 'loadNo', 'date', 'time', 'view'];
    mobileViewData: any;
    // nextPage(): void {
    //     this.paginationService.next();
    // }
    //
    //
    // prevPage(): void {
    //     this.paginationService.prev();
    // }
    todayDate: Date = new Date();
    enableInfoMsg = false;
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;

        this.mobileViewArrHead;
        this.mobileViewArrCell;

    }
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    getColumn(): any[] {
        return [
            {
                columnDef: 'Sr',
                header: 'Sr.',
                cell: (element: Load, index: number) => `${this.page * 10 + index + 1}`,

            },
            {
                columnDef: 'Sterilizer',
                header: 'Sterilizer',
                cell: (element: Load, index: number) => `${element.machineName}`
            },
            { columnDef: 'Type', header: 'Type', cell: (element: Load, index: number) => this.customType(element.type) },
            {
                columnDef: 'Program',
                header: 'Program',
                cell: (element: Load, index: number) => this.customProgram(element.type, element.program)
            },
            { columnDef: 'Load No', header: 'Load No', cell: (element: Load, index: number) => `${element.loadNo}` },
            { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Load, index: number) => `${element.cycleNo}` },
            { columnDef: 'Barcode', header: 'Barcode', cell: (element: Load, index: number) => `${element.barcode}` },
            {
                columnDef: 'BD', header: 'BD',
                cell: (element: Load, index: number) => `${element.barcodeBd ? element.barcodeBd : '-'}`,
                textColor: (element: Load, index: number) => `${this.getSingleLoadColor(element.statusBd)}`
            },
            {
                columnDef: 'BI', header: 'BI(Spore Test)',
                cell: (element: Load, index: number) => `${element.barcodeBi ? element.barcodeBi : '-'}`,
                textColor: (element: Load, index: number) => `${this.getSingleLoadColor(element.statusBi)}`
            },
            {
                columnDef: 'EI', header: 'PCD',
                cell: (element: Load, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
                textColor: (element: Load, index: number) => `${this.getSingleLoadColor(element.statusEi)}`
            },
            { columnDef: 'User', header: 'User', cell: (element: Load, index: number) => `${element.startedBy.name}` },
            { columnDef: 'Date', header: 'Date', cell: (element: Load, index: number) => `${element.date}` },
            { columnDef: 'Time', header: 'Time', cell: (element: Load, index: number) => `${element.time}` },
            {
                columnDef: 'Status', header: 'Status',
                cell: (element: Load, index: number) => `${this.getLoadStatus(element)}`,
                textColor: (element: Load, index: number) => `${this.getLoadColor(element)}`
            },
            { columnDef: 'AI Image', header: 'AI Image', cell: (element: Load, index: number) => `${element.aiImageUrl ? element.aiImageUrl : ''}` },

            { columnDef: 'view', header: 'View', cell: (element: Load, index: number) => `${element.machineReportUrl ? element.machineReportUrl : element.reportImageUrl ? element.reportImageUrl:''}` },

            // {
            //     columnDef: 'Sr1',
            //     header: 'Sr1.',
            //     cell: (element: Load, index: number) => 'link',
            //
            // },
        ];
    }

    customType(type) {
        if (type === 'BD_Test' || type === 'BI_Test') {
            const a = type.split('_', 1);

            return a[0];

        } else {

            return type;
        }
    }

    customProgram(type, program) {
        if (type === 'BI_Test') {
            return type;
        } else {
            return program;
        }

    }
    getLoadStatus(load: Load): string {
        return this.statusService.getMessage(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getLoadColor(load: Load): string {
        return this.statusService.getColor(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.startTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.startTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.time = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
    }

    getTitleMatIcon(): string {
        return 'grain';
    }

    getTitle(): string {
        return 'Load';
    }

    getSearchText(): string {
        return 'Search load via barcode';
    }

    viewLOad(barcode) {
        this.router.navigate(['/loaddetail'], { queryParams: { barcode: barcode } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {

        window.open(Url, '_blank');
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }



}
