import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })

export class ConsentService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 100;
    currentPage = 0;
    protected table = 'consent';
    protected orderBy = 'sortBy';
    protected isOrderByNegative = true;
    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }
    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }
    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                const doc = a.payload.doc;
                return { id, ...data as {}, doc };
            });
        }));
    }


    first(): any {
        this.currentPage = 0;
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .where(this.orderBy, '>', 0)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .limit(this.ROWS));
        }
        query.subscribe(data => {
            this.pageSubject.next(0);
            try {
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
            } catch (e) {
                // do something

            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }
    next(): void {
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.latestEntry)
                .where(this.orderBy, '>', 0)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.latestEntry)
                .limit(this.ROWS));
        }
        query.subscribe(data => {
            try {
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.currentPage++;
                this.pageSubject.next(this.currentPage);

            } catch (e) {
                // do something
            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();
        });
    }

    prev(): void {
        let query = null;
        if (this.isOrderByNegative) {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy, 'desc')
                .startAfter(this.startingEntry)
                .where(this.orderBy, '>', 0)
                .limit(this.ROWS));
        } else {
            query = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .startAfter(this.startingEntry)
                .limit(this.ROWS));
        }
        query.subscribe(data => {

            try {
                data.reverse();
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.currentPage--;
                this.pageSubject.next(this.currentPage);
            } catch (e) {
                // do something
            }
            this.itemsSubject.next(data);
            // scoresRef.unsubscribe();covidconsent
        });
    }

    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/covid/constant/' + this.table;
    }

    addConsentData(data) {
        return new Promise((resolve, reject) => {
            const collectionPath = `lab/${this.userService.getCookie("lab")}/covid/constant/${this.table}`;


            this.afs.collection(collectionPath).add(data).then(addedConsent => {

                resolve({ msg: 'Consent addedd successfully' });
            }).catch((error) => {
                reject(error);
            });
        })


    }

    deleteConsentById(docId) {
        const collectionPath = `lab/${this.userService.getCookie("lab")}/covid/constant/${this.table}`;

        return new Promise<any>((resolve, reject) => {
            this.afs.collection(collectionPath).doc(docId).delete().then((result) => {
                const msg = 'Team Member Successfully Deleted';
                this.currentPage = 0;
                resolve(msg);
            }).catch((error) => {
                reject('Error removing document');
            });
        });
    }

    getConsentById(docId) {

        const consentCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(consentCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }

    updateConsent(docId, consentData) {
        const consentCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(consentCollectionPath).doc(docId).set(consentData).then((resultData) => {
                // window.alert(`Team Member ${teamData.name} Added Successfully`);
                const data = { msg: 'Updated SuccessFully', status: 'success' };
                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }




}
