import {
    Component,
    Inject,
    Input,
    OnInit,
    EventEmitter,
    Output,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
} from "@angular/forms";
import { PatientService } from "../patient.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { Patient } from "../patient";
import { StorageService } from "app/main/module/common/service/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../../common/global";
import { ConfigService } from "../../../common/config/config.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ConformationDialogComponent } from "./conformation-dialog/conformation-dialog.component";
import { CovidService } from "../../../screening/covid.service";
import {Location} from '@angular/common';
@Component({
    selector: "share-assessment-dialog",
    templateUrl: "share-assessment-dialog.component.html",
    styleUrls: ["share-assessment-dialog.component.scss"],
})
export class ShareAssessmentDialogComponent implements OnInit {
    patientForm: FormGroup;
    mobileForm: FormGroup;
    naForm: FormGroup;
    platform = "web";
    email = "";
    patientId = "";
    firstName = "";
    lastName = "";
    fullName ="";
    comboId = "";
    mobileNo = "";
    mobileNo2 = "";
    doa: any;
    @Input()
    min: "D" | null;
    today = new Date();
    hrs = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
    ];
    minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    hour: string;
    minute: string;
    preHrs: Array<any> = [];
    dateIso: any;
    dateTime: any;
    date: any;
    disabled = false;
    showSpinners = true;
    showSeconds = false;
    touchUi = false;
    enableMeridian = true;
    // minDate: any;
    minDate = new Date();
    maxDate: any;
    stepHour = 1;
    stepMinute = 5;
    // defaultTime: any;
    defaultTime: any;
    showDialogue: any = false;
    defaultMediumValue: any;
    smsMediumDisabled = false;
    labConfig: any;
    // countryCode: any;
    token: any;
    labId: any;
    defaultCountryCode2:any;
   countryCodeList2:any = []
    @Output() countrySelectHandler: EventEmitter<any> = new EventEmitter();
    
    defaultCountryCode: any;
    loadingSpinner: boolean=true;
    countryCode = [];
    

    constructor(
        public dialogRef: MatDialogRef<ShareAssessmentDialogComponent>,
        private _formBuilder: FormBuilder,
        public patientService: PatientService,
        public snackBar: MatSnackBar,
        private userService: StorageService,
        private global: Globals,
        private httpClient: HttpClient,
        private router: Router,
        public dialog: MatDialog,
        private paginationService: CovidService,
        private baseConfigService: ConfigService,
        private location: Location,
        @Inject(MAT_DIALOG_DATA) public data: Patient
    ) {}

   
    // back(): void {
    //     this.location.back()
    //   }
    

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log(this.dialogRef.componentInstance.data);
        this.defaultTime = new Date(this.dialogRef.componentInstance.data.doa);
        this.dateIso = new Date().toISOString().slice(0, 16);
        this.dateTime = new Date().toISOString().slice(0, 16);
        // this.minDate = new Date().toISOString().slice(0, 16);
        this.email = this.dialogRef.componentInstance.data.emailId;
        this.patientId = this.dialogRef.componentInstance.data.patientId;
        this.firstName = this.dialogRef.componentInstance.data.firstName;
        this.lastName = this.dialogRef.componentInstance.data.lastName;
        this.fullName = this.camelize(this.firstName) + " " + this.camelize(this.lastName);
        this.comboId = this.dialogRef.componentInstance.data.comboId;
        if(this.dialogRef.componentInstance.data.mobileNo !== undefined){
            this.mobileNo2 = this.dialogRef.componentInstance.data.mobileNo;
            if(this.mobileNo2.length > 10){
                this.mobileNo = this.mobileNo2.slice(this.mobileNo2.length - 10);
                }else{
                this.mobileNo = this.mobileNo2;
                }
            }else{
            this.mobileNo = ''
            }
            
            console.log(this.dialogRef.componentInstance.data.countryCode)
            if(this.dialogRef.componentInstance.data.countryCode !== 0){
                this.defaultCountryCode2 = this.dialogRef.componentInstance.data.countryCode;
                console.log(this.defaultCountryCode2)
                } 
        this.labId = this.userService.getCookie("lab");
        this.countryCode = this.getCountryList();

        this.patientForm = this._formBuilder.group({
            email: [
                "",
                [
                    Validators.required,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ],
                "",
            ],
            // doa: ['', [Validators.required],],
            // hour: ['', [Validators.required],],
            // minute: ['', [Validators.required],],
            minDate: ["", []],
            dateTime: [{ value: this.defaultTime }, [Validators.required]],
        });

        this.mobileForm = this._formBuilder.group({

            countryCode: [''],
            mobileNo: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?))?[0-9]{10}$"),
                ],
            ],
            minDate: ["", []],
            dateTime: [{ value: this.defaultTime }, [Validators.required]],
            
        });
        
        this.naForm = this._formBuilder.group({
            minDate: ["", []],
            dateTime: [{ value: this.defaultTime }, [Validators.required]],
        });
        this.preHrs = this.hrs; // to keep previous value every selection

        this.patientService
            .getCovidConfig(this.userService.getCookie("lab"))
            .then((data) => {
                // console.log(data.defaultMedium);
                this.smsMediumDisabled = data.smsMediumDisabled;
                // console.log(this.smsMediumDisabled);

                if (data.defaultMedium.includes("other")) {
                    if (this.email !== undefined && this.email.length !== 0) {
                        this.defaultMediumValue = "email";
                    } else if (
                        this.mobileNo2 !== undefined && this.mobileNo2.length !== 0 && this.defaultCountryCode2 !== undefined
                    ) {
                        this.defaultMediumValue = "sms";
                    } else {
                        this.defaultMediumValue = "other";
                    }
                } else {
                    this.defaultMediumValue = data.defaultMedium;
                }
            });

        this.baseConfigService
            .getLabConfig("lab")
            .valueChanges()
            .subscribe((labConfig: any) => {
                this.labConfig = labConfig;
                // console.log(this.labConfig.sendSMSFromNumber);
            });
    }

    onChangeEmail() {
        this.defaultMediumValue = "email";
    }
    getCountryList() {
        return [
        
            { id: '1', code: "+1" },
            { id: '2', code: "+91" },
            { id: '3', code: "+355" },
            { id: '4', code: "+213" },
            { id: '4', code: "+376" },
            { id: '4', code: "+244" }
           
        ];
      }
      onNoClick(): void { 
        this.dialogRef.beforeClosed();   
    }
    onChangeForSms() {
        this.defaultMediumValue = "sms";
    }
   
    onChangeForNA() {
        this.defaultMediumValue = "other";
    }

    clickDobDatepicker(event: any) {
        const getDay = new Date();
        if (moment(event.value).toDate().getDate() === getDay.getDate()) {
            const filteredHrs = this.hrs.filter((value) => {
                if (value > getDay.getHours()) {
                    return value;
                }
            });
            this.hrs = filteredHrs;
        } else {
            this.hrs = this.preHrs;
        }
        sessionStorage.setItem(
            "doa",
            moment(event.value).toDate().getTime().toString()
        );
    }

    changeEmail() {
        this.patientForm.reset();
    }

    addPatientDetail() {
        // sending data to patient module
        this.dialogRef.close(this.patientForm.value);
    }

    sendMessage() {
        // console.log(this.mobileNo)
        // console.log('value=',this.mobileForm.value)
        this.dialogRef.close(this.mobileForm.value); 

    }

    processedPreScreening() {
        console.log('share link call');
        this.dialogRef.close('OTHER');
        this.dialog.open(ConformationDialogComponent, {
            data: {
                patientId: this.patientId,
                email: this.email,
                phone:this.mobileNo,
                firstName: this.firstName,
                lastName: this.lastName,
                dateTime: this.naForm.value.dateTime,
                comboId: this.comboId,
                labId: this.userService.getCookie("lab"),
            },
        });
    }
    camelize(str) {

        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
        }).replace(/\s+/g, '');
        }

    getMapValue(stringData) {
        const param = stringData.replace(/\./g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    addZeroToHourAndMinutes(value: any) {
        if (value < 10) {
            return "0" + value;
        }
        return value;
    }

    // to uppercase string

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

    toggleMinDate(evt: any) {
        if (evt.checked) {
            this._setMinDate();
        } else {
            this.minDate = null;
        }
    }

    toggleMaxDate(evt: any) {
        if (evt.checked) {
            this._setMaxDate();
        } else {
            this.maxDate = null;
        }
    }

    private _setMinDate() {
        const now = new Date();
        this.minDate = new Date();
        this.minDate.setDate(now.getDate() - 1);
    }

    private _setMaxDate() {
        const now = new Date();
        this.maxDate = new Date();
        this.maxDate.setDate(now.getDate() + 1);
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }
    // onSelectCountryCode($event) {
    //     const codeValue = $event.target.value;
    //     this.countryCode = JSON.parse(codeValue).code;
    //     sessionStorage.setItem("selectedCountryCode", this.countryCode);
    //     console.log(this.countryCode);
    //     // this.countrySelectHandler.emit(
    //     //     this.countryCode ? JSON.parse(this.countryCode) : null
    //     // );
    // }

    stringify(item) {
        return JSON.stringify(item);
    }
}
