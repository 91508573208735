import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labelbarcode',
  templateUrl: './labelbarcode.component.html',
  styleUrls: ['./labelbarcode.component.scss']
})
export class LabelbarcodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
