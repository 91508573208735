import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { ConsentService } from '../list/consent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fakeAsync } from '@angular/core/testing';

@Component({
    selector: 'app-addcovidconsent',
    templateUrl: './addcovidconsent.component.html',
    styleUrls: ['./addcovidconsent.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AddcovidconsentComponent implements OnInit {

    registerForm: FormGroup;
    selected = 'No';
    hasCheckbox = false;

    constructor(private _formBuilder: FormBuilder, private consentService: ConsentService,
        public snackBar: MatSnackBar,) { }

    ngOnInit(): void {

        this.registerForm = this._formBuilder.group({
            option: ['', [Validators.required], ''],
            sortBy: ['', [Validators.required],],
            hasCheckbox: ['', [Validators.required],],
        });

    }

    addConsent() {

        if (this.registerForm.value.hasCheckbox === 'Yes') {
            this.hasCheckbox = true;
        } else {
            this.hasCheckbox = false;
        }
        const consentData =
        {
            option: this.registerForm.value.option.trim(),
            hasCheckbox: this.hasCheckbox,
            sortBy: parseInt(this.registerForm.value.sortBy.trim(), 10),
        };
        const returnData = this.consentService.addConsentData(consentData);
        returnData.then((data: any) => {
            const msg = 'Consent Added Successfully';
            this.openSnackBar(msg, 'OK');
        }).catch((error) => {
            this.handleError(error);
        });
        this.registerForm.reset();

    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }


}
