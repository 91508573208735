import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CovidDto } from './CovidDto.modal';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore/interfaces';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../common/global';
import { ApiService } from '../common/service/api.service';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { StorageService } from 'app/main/module/common/service/storage.service';


@Injectable()
export class CovidService {
    private itemsSubject: BehaviorSubject<CovidDto[]>;
    public itemsObservable: Observable<CovidDto[]>;

    private searchSubject: BehaviorSubject<CovidDto[]>;
    public searchObservable: Observable<CovidDto[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    table = 'patientResult';
    resultTable = 'patientResult';
     orderBy = 'doaTimestamp';
    //   orderBy = 'doa';
    currentPage = 0;
    public notFoundSubject: BehaviorSubject<boolean>;
    public notFoundObservable: Observable<boolean>;
    protected isOrderByNegative = true;
    protected searchBy = 'barcode';
    public searchedText: string = null;
    public searchedFrom = 0;
    public searchedTo = 0;
    hasFirstDataShown = false;
    paginationSpinner: boolean;
    patientIdCol = 'patientId';

    constructor(private afs: AngularFirestore, public globals: Globals,
        private userService: StorageService,
        public httpClient: HttpClient, public apiService: ApiService) {
    }

    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
        console.log(this.orderBy);
    }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }


    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const docId = a.payload.doc.id;
                const doc = a.payload.doc;
                return { docId, ...data as {}, doc };
            });
        }));
    }

    getSearchCollection(covidDto): Observable<any[]> {

        if (covidDto) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where(this.covidDtoSearchQuery(covidDto.toUpperCase()), '==', true)
                // .where('doa','>=', this.searchedFrom)
                // .where('doa','<=', this.searchedTo)
                // .orderBy(this.orderBy, 'desc')
                .limit(1000));
           // .limit(this.ROWS));

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }

    getCovidDto(covidDto): Observable<CovidDto[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        console.log('searchObservable=',this.searchObservable );
        const ref = this.getSearchCollection(covidDto.toUpperCase())
            .subscribe(data => {
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);

                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.searchSubject.next(null);

                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    search(): any {
        console.log(this.searchedText);
        if (this.searchedText) {
// console.log('if');
            this.getCovidDto(this.searchedText).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            // console.log('else');
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS)
        )
            .subscribe(data => {
                this.pageSubject.next(0);
                if (data) {
                    this.latestEntry = data && data.length > 0 && data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                } else {
                    // do something
                }
                // scoresRef.unsubscribe();
            });
            // const ref = this.getCollection(this.getCollectionPath(), ref => ref
            //     .orderBy(this.orderBy, 'desc')
            //     .limit(this.ROWS))
            //     .subscribe(data => {
            //         this.pageSubject.next(0);
            //         if (data) {
            //             this.latestEntry = data[data.length - 1].doc;
            //             this.startingEntry = data[0].doc;
            //             this.itemsSubject.next(data);
            //         } else {
            //             // do something
            //         }
            //         // scoresRef.unsubscribe();
            //     });
        }
    }


    // Today search

    getSearchTodayCollection(condition): Observable<any[]> {
        const start = moment().format('YYYY-MM-DD 00:00:01');
        const end = moment().format('YYYY-MM-DD 23:59:59');

        let startTimestamp: any = moment(start).format('x');
        let endTimestamp: any = moment(end).format('x');
        startTimestamp = (-1) * startTimestamp;
        endTimestamp = (-1) * endTimestamp;

        if (condition) {
            if (condition === 'today') {

                return this.getCollection(this.getCollectionPath(), ref => ref
                    .where('doa', '>=', endTimestamp)
                    .where('doa', '<=', startTimestamp)
                    .limit(1000));
            }
            if (condition === 'history') {
                return this.getCollection(this.getCollectionPath(), ref => ref
                    .where('doa', '>=', startTimestamp)
                    .orderBy('doa', 'desc')
                    .limit(1000));
            }

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }




    // range filter


    getSearchRangeCollection(condition): Observable<any[]> {
        const startTimestamp = condition.from;
        console.log(startTimestamp)
        const endTimestamp = condition.to;
        console.log(endTimestamp)

        if (condition) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where('doa', '>=', startTimestamp)
                .where('doa', '<=', endTimestamp)
                .orderBy('doa', 'desc')
                .limit(1000));

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }


    searchRange(condition): any {
        console.log(condition);
        if (condition) {
            this.getCovidDtoRange(condition).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy('doa', 'desc')
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something
                    }
                    // scoresRef.unsubscribe();
                });
        }
    }




    getCovidDtoRange(condition): Observable<CovidDto[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        const ref = this.getSearchRangeCollection(condition)
            .subscribe(data => {
                this.hasFirstDataShown = false;
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);
                    this.hasFirstDataShown = true;
                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.hasFirstDataShown = false;
                    this.searchSubject.next(null);

                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                    this.hasFirstDataShown = true;
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }



    getCovidDtoToday(condition): Observable<CovidDto[]> {
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        const ref = this.getSearchTodayCollection(condition)
            .subscribe(data => {
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);
                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.searchSubject.next(null);
                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }

    searchToday(condition): any {
        if (condition) {
            this.getCovidDtoToday(condition).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy(this.orderBy)
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something
                    }
                    // scoresRef.unsubscribe();
                });
        }
    }


    first(): any {
        this.currentPage = 0;
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        this.notFoundSubject = new BehaviorSubject<boolean>(false);
        this.notFoundObservable = this.notFoundSubject.asObservable();
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS)
        )
            .subscribe(data => {
                this.pageSubject.next(0);
                if (data) {
                    this.latestEntry = data && data.length > 0 && data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.itemsSubject.next(data);
                } else {
                    // do something
                }
                // scoresRef.unsubscribe();
            });
    }

    public test() {
        console.log('hit');
        const patientCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/covid/result/' + 'stories';
       // const db = firebase.firestore();
       // const increment = firebase.firestore.FieldValue.increment(1);
        const decrement = firebase.firestore.FieldValue.increment(-1);
        
        return this.afs.collection(patientCollectionPath).doc('covidId').update({ reads: decrement });
    
    }
    updateCovidResult2(tokenId){

        const patientCollectionPath = this.getCovidResultPath();
      
        let query = this.afs.collection(patientCollectionPath).ref.where('tokenId', '==', tokenId);
     
        return query;
        
        // return this.getCollection(patientCollectionPath, ref => ref
        
        // .where('tokenId', '==', tokenId)
        
        // .limit(1));
        
        }
    
    public next(): void {
        this.paginationSpinner=false;
        this.getNextQuery().subscribe(data => {
            if (data && data.length > 0) {
                this.currentPage++;
                this.pageSubject.next(this.currentPage);
                // And save it again for more queries
                this.latestEntry = data[data.length - 1].doc;
                this.startingEntry = data[0].doc;
                this.itemsSubject.next(data);
                this.paginationSpinner=true;
            } else {
                if (this.notFoundSubject) {
                    this.paginationSpinner=true;
                    this.notFoundSubject.next(true);
                    this.notFoundSubject.next(false);    
                }
            }
            // scoresRef.unsubscribe();
        });
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .startAfter(this.startingEntry)
            .limit(this.ROWS));

    }

    prev(): void {
this.paginationSpinner=false; 
            this.getPrevQuery().subscribe(data => {
                if (data.length) {
                    data.reverse();
                    // And save it again for more queries
                    this.latestEntry = data[data.length - 1].doc;
                    this.startingEntry = data[0].doc;
                    this.currentPage--;
                    this.pageSubject.next(this.currentPage);
                    this.itemsSubject.next(data);
                    this.paginationSpinner=true;
                }
                // scoresRef.unsubscribe();
            });
        
        // const ref = this.getCollection(this.getCollectionPath(), ref => ref
        //     .orderBy(this.orderBy)
        //     // Now you can use the latestEntry to query with startAfter
        //     .startAfter(this.startingEntry)
        //     .limit(this.ROWS))
        //     .subscribe(data => {
        //         try {
        //             if (data && data.length) {
        //                 data.reverse();
        //                 // And save it again for more queries
        //                 this.latestEntry = data[data.length - 1].doc;
        //                 this.startingEntry = data[0].doc;

        //                 this.currentPage--;
        //                 this.pageSubject.next(this.currentPage);
        //                 this.itemsSubject.next(data);
        //             }
        //         } catch (e) {
        //             // do something
        //         }
        //         this.itemsSubject.next(data);
        //         // scoresRef.unsubscribe();
        //     });
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .limit(this.ROWS));
    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie('lab') + '/covid/result/' + this.table;
    }

    covidDtoSearchQuery(covidDto): string {
        return 'anyMap.' + covidDto;
    }


    //    add covidDto starts

    addCovidDto(covidDtoData: any, docId) {

        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).set(covidDtoData).then((resultData) => {
                const data = { msg: 'CovidDto Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    getCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(covidDtoCollectionPath).doc(docId).valueChanges().subscribe(data => {
                resolve(data);
            });
        });
    }

    deleteCovidDtoById(docId) {
        const covidDtoCollectionPath = this.getCollectionPath();
        // this.afs.collection(covidDtoCollectionPath).doc(docId).delete().then(() => {
        // }).catch((error)=> {
        // })

        return this.afs.collection(covidDtoCollectionPath).doc(docId).delete();


    }


    //    add patient starts

    getCovidResultPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/covid/result/' + this.resultTable;
    }

    getCovidGuestResultPath(): string {
        return 'covidGuest/';
    }

    addCovidResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(resultData);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    addCovidResultWithoutLogin(covidResultData: any, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(resultData);
            }).catch((error) => {
                reject(error);
            });

        });
    }


    addCovidGuestResult(covidResultData: any) {
        const patientCollectionPath = this.getCovidGuestResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).add(covidResultData).then((resultData) => {
                const data = { msg: 'Covid Result Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });

        });
    }


    getCovidResultById(docId) {
        const patientCollectionPath = this.getCovidResultPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }


    checkCovidResultByIdWithoutLogin(docId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).get().subscribe(async (data) => {
                resolve(await data.data());

            });
        });
    }

    getCovidResultByIdWithoutLogin(docId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;

        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {

                resolve(data);
            });
        });
    }

    updateCovidResult(covidData, covidId) {
        console.log('covidId',covidData,covidId)
        const patientCollectionPath = this.getCovidResultPath();
        return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData);
    }

    updateCovidResultWithoutLogin(covidData, covidId, labName) {
         console.log('covidData=',covidData)
         console.log('covidId=',covidId)
         console.log('labName=',labName)
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        return this.afs.collection(patientCollectionPath).doc(covidId).set(covidData);
        // return this.afs.collection(patientCollectionPath).doc(covidId).update(covidData);
    }


    setCovidResultWithoutLogin(covidData, covidId, labName) {
        const patientCollectionPath = 'lab/' + labName + '/covid/result/' + this.resultTable;
        return this.afs.collection(patientCollectionPath).doc(covidId).set(covidData);
    }

    createDocId(patId) {
        const date = new Date();
        const rand = Math.floor(Math.random() * 1000) + 1;
        const uniqId = date.getTime() + rand.toString();
        return uniqId;
    }

    shareLinkUrl(getLoadData): Observable<any> {
        const lab = this.userService.getCookie("lab");
        let url = this.globals.siteUrl + 'covid-assessment-start?token=';

        // const urlStr = '?key=add&patient='+this.globals.encodeToa(getLoadData.patientId)+'&labName='+this.globals.encodeToa(lab);
        // const docId = this.createDocId(getLoadData.patient);

        const urlStr = 'key#add##patient#' + getLoadData.patientId + '##labName#' + lab + '##email#' + getLoadData.email + '##firstName#' + getLoadData.firstName + '##lastName#' + getLoadData.lastName + '##docId#' + getLoadData.docId;
        url = url + this.globals.encodeToa(urlStr);
        const data: any = {
            email: getLoadData.email,
            url: url,
            fullName: getLoadData.fullName,
            patientId: getLoadData.patientId,
            labId: getLoadData.labId,
            dateTime: getLoadData.dateTime,
        };

        return this.apiService.shareLinkUrl(data);

    }

    getLabLogo(collectionPath) {
        return this.afs.collection(collectionPath).doc('lab');
    }

    async getLastTokenNo() {
        return new Promise((resolve, reject) => {
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy('tokenId', 'desc')
                .limit(1))
                .subscribe(data => {
                    if (data && data.length > 0) {
                        data.map(val => {
                            resolve(val.tokenId + 1);
                            // return val.tokenId+1;
                        });
                    } else {
                        resolve(null);
                    }

                });
        });
    }


    // being use in covid screening ID

    getTokenInitValue() {
        return new Promise<any>((resolve, reject) => {
            const collectionName = `lab/${this.userService.getCookie("lab")}/config`;
            this.afs.collection(collectionName).doc('covid').get().subscribe((doc) => {
                if (doc) {
                    resolve(doc.data());
                } else {
                    reject(' Error in reading the role details');
                }
            });
        });
    }

    getPhoneNumber(labId) {
        return new Promise<any>((resolve, reject) => {
            const collectionName = `lab/${labId}/config`;
            this.afs.collection(collectionName).doc('phone').get().subscribe((doc) => {
                if (doc) {
                    resolve(doc.data());
                } else {
                    reject(' Error in reading the role details');
                }
            });
        });
    }

    async tempLogin() {
        return firebase.auth().signInWithEmailAndPassword(this.globals.staticEmail, this.globals.staticPass)
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;

            });
    }

    getCollectionPathConsent(): string {
        return `lab/${this.userService.getCookie("lab")}/covid/constant/consent`;
    }

    getAllConsentData(labId) {

        const consentPath = `lab/${labId}/covid/constant/consent`;
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(consentPath, ref => ref.orderBy('sortBy')).valueChanges().subscribe(data => {
                if (data) {
                    resolve(data.map((value: any) => value));
                }
            })
        })

    }

    getAllQuestionData(labId) {

        let questionObject = {};
        const questCollectionPath = `lab/${labId}/covid/constant/question`;
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(questCollectionPath).valueChanges().subscribe(data => {
                if (data) {

                    data.forEach((val, index, arr) => {
                        questionObject = Object.assign(questionObject, val)
                        resolve(questionObject);

                    })
                }
            })
        })

    }

    getSearchRangeCollectionAfterSearchPatient(condition,covidDto): Observable<any[]> {
        const startTimestamp = condition.from;
        // console.log(startTimestamp)
        const endTimestamp = condition.to;
        // console.log(endTimestamp)

        if (condition) {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .where('doa', '>=', startTimestamp)
                .where('doa', '<=', endTimestamp)
                .orderBy('doa', 'desc')
                .where(this.covidDtoSearchQuery(covidDto.toUpperCase()), '==', true)
                .limit(1000));

        } else {
            return this.getCollection(this.getCollectionPath(), ref => ref
                .limit(this.ROWS));
        }
    }


    searchRangeAfterSearchPatient(condition,searchedText): any {
        // console.log(condition,patientId);
        if (condition) {
            this.getCovidDtoRangeAfterSearchPatient(condition,searchedText).subscribe(covidDto => {
                this.itemsSubject.next(covidDto);
            });
        } else {
            console.log(condition,this.searchedText);
            const ref = this.getCollection(this.getCollectionPath(), ref => ref
                .orderBy('doa', 'desc')
                // .where(this.patientIdCol, '==', patientId)
                .limit(this.ROWS))
                .subscribe(data => {
                    this.pageSubject.next(0);
                    if (data) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);
                    } else {
                        // do something
                    }
                    // scoresRef.unsubscribe();
                });
        }
    }




    getCovidDtoRangeAfterSearchPatient(condition,covidDto): Observable<CovidDto[]> {
        // console.log(condition,patientId);
        this.searchSubject = new BehaviorSubject([]);
        this.searchObservable = this.searchSubject.asObservable();
        const ref = this.getSearchRangeCollectionAfterSearchPatient(condition,covidDto.toUpperCase())
            .subscribe(data => {
                this.hasFirstDataShown = false;
                if (data && data.length > 0) {
                    // this.searchSubject.next(data);
                    this.hasFirstDataShown = true;
                    this.currentPage = 0;
                    this.pageSubject.next(0);
                    this.searchSubject.next(data);

                } else {
                    this.hasFirstDataShown = false;
                    this.searchSubject.next(null);

                    // this.notFoundSubject.next(true);
                    // this.notFoundSubject.next(false);
                    this.hasFirstDataShown = true;
                }
                // scoresRef.unsubscribe();
            });
        return this.searchObservable;
    }


    getAllQuestionOverageData(labId) {
        let questionObject = {};
        const questCollectionPath = `lab/${labId}/covid/constant/questionOvereage`;
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(questCollectionPath).valueChanges().subscribe(data => {
                if (data) {
                    data.forEach((val, index, arr) => {
                        questionObject = Object.assign(questionObject, val)
                        resolve(questionObject);

                    })
                }
            })
        })

    }


    getCovidResultByIdV2(docId) {
        const labId = this.userService.getCookie("lab");
        const questCollectionPath = `lab/${labId}/covid/constant/patientQuestion`;
        return new Promise((resolve, reject) => {
            this.afs.collection(questCollectionPath).doc(docId).valueChanges().subscribe(data => {
                resolve(data);
            });
        });
    }


    getAllResolution(labId) {
        const questCollectionPath = `lab/${labId}/covid/`;
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(questCollectionPath).doc('config').valueChanges().subscribe(data => {
                if (data) {
                    resolve(data);
                }
            })
        })

    }

    checkPatientExistOrNot(docId, labId) {
        const patientCollectionPath =  `lab/${labId}/patient/`;
        return new Promise((resolve, reject) => {
            this.afs.collection(patientCollectionPath).doc(docId).get().subscribe(async (data) => {
                resolve(await data.data());

            });
        });
    }

}