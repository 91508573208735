import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CassetteConfig } from './config/cassetteConfig';
import { LabConfig } from './config/labConfig';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class Role {

    constructor() {

    }

    authRole = ['support','admin','user'];
    stateUrl = ['/team'];


}

