import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from "../../common/global";


@Injectable()
export class ReportService {
    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
    ) {}
    downloadPdf(getLoadData, labsList, reportId, patientId, patientName, timezone) {
        const data = {getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
        return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    }

    downloadPdfCenterWize(centerName,getLoadData, labsList, reportId, patientId, patientName, timezone) {
        const data = { centerName:centerName,getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
        return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    }
}
