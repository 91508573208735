import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CassetteConfig } from './config/cassetteConfig';
import { LabConfig } from './config/labConfig';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class Globals {

    superadmin = [
        { name: 'superadmin', abbrev: 'Super Admin', role: 1 },
        { name: 'admin', abbrev: 'Admin', role: 2 },
        { name: 'user', abbrev: 'User', role: 3 },
    ];

    admin = [
        { name: 'user', abbrev: 'User', role: 3 },
    ];
    user = [
        {}
    ];

    staticEmail = 'rakesh.pandey@xccelerata.com';
    staticPass = 'abc123';

    constructor() {
        this.abc();  // test function
    }

    dbUrl = environment.firebaseConfig.projectId;
    // https://us-central1-dentistry-project-qa.cloudfunctions.net/googleSignIn
    // constUrl = 'https://us-central1-dentistry-project-qa.cloudfunctions.net/';
    constUrl = environment.firebaseConfig.cloudFunctionURL;
    siteUrl = environment.firebaseConfig.websiteUrl;
    // siteUrl ='https://dentistry-project-qa.firebaseapp.com';

    logoPath = environment.firebaseConfig.storageBucket + '/';
    staticFlag = '';
    cassetteConfig: CassetteConfig = null;
    teamMemberConfig = null;
    labConfig: LabConfig = null;
    siteLogo = 'assets/images/logos/fuse.png';
    perPageData = 10;

    tokenId = 1000000000;
    secretKey = 'sterilwize@1909';

    abc() {
        // do something
    }

    decodeToa(b64) {
        return decodeURIComponent(escape(atob(b64)));
    }
    /**
     * Unicode to ASCII (encode data to Base64)
     * @param {string} data
     * @return {string}
     */
    encodeToa(data) {
        return btoa(unescape(encodeURIComponent(data)));
    }

    getLocalTimezoneName() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    getDefaultTimeZone(){
        return 'America/Toronto';
    }

}

