export class CovidSetting {
    homeHighRiskResolution: string;
    homeProbableRiskResolution: string;
    homeModerateRiskResolution: string;
    homeLowRiskResolution: string;
    homeRiskRiskResolution: string;

    inOfficeHighRiskResolution: string;
    inOfficeProbableRiskResolution: string;
    inOfficeModerateRiskResolution: string;
    inOfficeLowRiskResolution: string;
    inOfficeRiskRiskResolution: string;


    /**
     * Constructor
     *
     * @param product
     */
    constructor(product?) {
        product = product || {};
        this.homeHighRiskResolution = product.homeHighRiskResolution || '';
        this.homeProbableRiskResolution = product.homeProbableRiskResolution || '';
        this.homeModerateRiskResolution = product.homeModerateRiskResolution || '';
        this.homeLowRiskResolution = product.homeLowRiskResolution || '';
        this.homeRiskRiskResolution = product.homeRiskRiskResolution || '';

        this.inOfficeHighRiskResolution = product.inOfficeHighRiskResolution || '';
        this.inOfficeProbableRiskResolution = product.inOfficeProbableRiskResolution || '';
        this.inOfficeModerateRiskResolution = product.inOfficeModerateRiskResolution || '';
        this.inOfficeLowRiskResolution = product.inOfficeLowRiskResolution || '';
        this.inOfficeRiskRiskResolution = product.inOfficeRiskRiskResolution || '';



    }

    toString(): string {
        return
        this.homeHighRiskResolution +
        this.homeProbableRiskResolution +
        this.homeModerateRiskResolution +
        this.homeLowRiskResolution +
        this.homeRiskRiskResolution +

        this.inOfficeHighRiskResolution +
        this.inOfficeProbableRiskResolution +
        this.inOfficeModerateRiskResolution +
        this.inOfficeLowRiskResolution +
        this.inOfficeRiskRiskResolution


    }
}
