import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Globals } from '../global';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { sendEmailIfSelectedAnyYes } from '../../../../../../functions/src/api/covid/patient/sendEmailIfSelectedAnyYes';
const moment = require('moment-timezone');
import {StorageService } from './storage.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    constructor(public globals: Globals,
        public httpClient: HttpClient, public storageServise: StorageService, public afs: AngularFirestore) {

    }
    private functions = firebase.app().functions('us-central1');
    // private functions = firebase.app().functions('us-central1');


    shareLinkUrl(body): Observable<any> {
        return this.httpClient.post(this.globals.constUrl + 'sendCovidAssessmentEmail', body);
    }

    shareStaffAssessmentLink(body): Observable<any> {
        return this.httpClient.post(this.globals.constUrl + 'onRequestStaffAssessment', body);
    }

    shareBarcodeLinkUrl(labId: string, barcodes: string[], patientId: string,centerId:string) {
        const data: any = {
            labId: labId,
            barcodes: barcodes,
            patientId: patientId,
            centerId :centerId,
            platform: 'web',
            localTimeZone: this.globals.getLocalTimezoneName(),
        };
        // return this.httpClient.post(this.globals.constUrl + 'sendCheckOutEmail', data);
        const sendCheckOutEmailOncall = this.functions.httpsCallable('OnCallSendCheckoutEmail');
        return sendCheckOutEmailOncall(data);
    }

    startAssessment(data) {
        const testV2 = this.functions.httpsCallable('testV2');
        return testV2({ msg: 'Hi there' });
    }

    getYearlyChartData(labsList) {
        const data = { labsList: labsList };
        const sendYearlyChartData = this.functions.httpsCallable('getYearlyChart');
        return sendYearlyChartData(data);
    }

    updateCurrentMonthLoad(labsList) {
        const data = { labsList: labsList };
        const sendYearlyChartStatsData = this.functions.httpsCallable('loadChartstatsMonthly');
        return sendYearlyChartStatsData(data);
    }


    /** getting data for report chart on dashboard */

    getCountDataForAll3(getLoadData, labsList) {
        const data1 = { getLoadData: getLoadData[0], labsList: labsList };
        const data2 = { getLoadData: getLoadData[1], labsList: labsList };
        const data3 = { getLoadData: getLoadData[2], labsList: labsList };
        const currentWeek = this.functions.httpsCallable('bdBiEiTotalCounts');
        const prevWeek1 = this.functions.httpsCallable('bdBiEiTotalCounts');
        const prevWeek2 = this.functions.httpsCallable('bdBiEiTotalCounts');
        return forkJoin([currentWeek(data1), prevWeek1(data2), prevWeek2(data3)]);
    }

    sendMailIfPatientSelectedAnyYes(data) {
        console.log(data);
        return this.httpClient.post(this.globals.constUrl + 'sendEmailIfSelectedAnyYes', data);
    }

    sendMailStaffFromTeamModule(data) {
        // function need to be called here
        return this.httpClient.post(this.globals.constUrl + 'onRequestStaffAssessment', data);
    }

    generateConsentPdf(data) {
        // const labId = localStorage.getItem('lab');
        // const data = {labId: labId,docId:'XZWzMJDq51hFCIBn6aFN'};
        console.log('consentpdf=',data);
        data['localTimeZone'] = this.globals.getLocalTimezoneName();  // time zone added
        const generateConsentPdf = this.functions.httpsCallable('generateConsentPdf');
        return generateConsentPdf(data);

    }

    getLabid(){
        const getCollectionPath ='lab/'+this.storageServise.getCookie('lab')+'/config';
        return new Promise<any>((resolve, reject)=>{
        this.afs.collection(getCollectionPath).doc('lab').valueChanges().subscribe(data =>{
        console.log(data);
        resolve(data);
        })
        })
     }

    generatePatientCovidPdf(labId: string, patientId: string,tokenId:number) {
        const data: any = {
            labId: labId,
            patientId: patientId,
            tokenId:tokenId,
            localTimeZone: this.globals.getLocalTimezoneName(),
        };
        // return this.httpClient.post(this.globals.constUrl + 'sendCheckOutEmail', data);
        const sendpdfResult = this.functions.httpsCallable('generatePatientCovidPdf');
        return sendpdfResult(data);
    }


    getRoleAndPermission(labId: string) {
        const data: any = {
            labId: labId,
        };
        const sendpdfResult = this.functions.httpsCallable('roleAndPermission');
        return sendpdfResult(data);
    }

    getPageConfigPermission(labId: string) {
        const data: any = {
            labId: labId,
        };
        const sendpdfResult = this.functions.httpsCallable('pageConfigPermission');
        return sendpdfResult(data);
    }

    getNavigationSetting(labId: string) {
        const data: any = {
            labId: labId,
        };
        const settingResult = this.functions.httpsCallable('getNavigationSetting');
        return settingResult(data);
    }

    getFileContent() {
        const data ={fileName: 'https://firebasestorage.googleapis.com/v0/b/dentistry-project-dev.appspot.com/o/lab%2FmuseumDental%2FmachineFiles%2FBravo%2F00107%252009_12_19%252014_36_58%2520Pouches%2520%26%2520Wrapped%2520Cassettes%2520MN-111%2520142004.htm?alt=media&token=53e10547-00e9-433d-9047-d22553971733'};

        return this.httpClient.post(this.globals.constUrl + 'getFileContent', data);
    }


// getFileOnCall

    getFileOnCall(){
        const data: any = {
            labId: 'labId',
        };
        const returnString = this.functions.httpsCallable('getFileOnCall');
        return returnString(data);

    }

    getDateStringMomentByTz(timestamp: any): string {
        if(timestamp){
            if (timestamp < 0)
                timestamp = timestamp * -1;
            const localTimeZone =  moment(timestamp).tz(this.storageServise.getCookie('defaultTz'));
            return localTimeZone.format('DD-MM-YYYY | HH:mm');
        }
       return '';
    }

    getDateStringMomentByTzOnlyDate(timestamp: number): string {
        if (timestamp < 0)
            timestamp = timestamp * -1;
        const localTimeZone =  moment(timestamp).tz(this.storageServise.getCookie('defaultTz'));
        return localTimeZone.format('DD-MM-YYYY');
    }
    getDateStringMomentByTzOnlyTime(timestamp: number): string {
        if (timestamp < 0)
            timestamp = timestamp * -1;
        const localTimeZone =  moment(timestamp).tz(this.storageServise.getCookie('defaultTz'));
        return localTimeZone.format('HH:mm');
    }

    getDateStringMomentByTzOnlyTimeHMS(timestamp: number): string {
        if (timestamp < 0)
            timestamp = timestamp * -1;
        const localTimeZone =  moment(timestamp).tz(this.storageServise.getCookie('defaultTz')).format('HH:mm:ss');
        return localTimeZone;
    }
    getDateStringMomentByTzOnlyTimeWithAMPM(timestamp: number): string {
        if (timestamp < 0)
            timestamp = timestamp * -1;
        const localTimeZone =  moment(timestamp).tz(this.storageServise.getCookie('defaultTz'));
        return localTimeZone.format('HH:mm A');
    }

    getLabelUsage(fromDate , toDate ){
        const data: any = {
            labId: this.storageServise.getCookie('lab'),
            from:fromDate,
            to: toDate,
        };
        const returnString = this.functions.httpsCallable('labelAverageUsage');
        return returnString(data);

    }


    getLabCenterList(){
        const data: any = {
            labId: this.storageServise.getCookie('lab'),
        };
        const returnString = this.functions.httpsCallable('getLabCenterList');
        return returnString(data);

    }
//     getLabCenterList




    addAnalytics(){
        const data: any = {
            labId: this.storageServise.getCookie('lab'),
            serialNumber:'rakesh',
            wifiStrength:11,
                wifiChannel:1,
                updatedTime:124565754747,
            googleId:'abc@gmail.com',
            appVersion:'23.1.2.3',
            center:'NewCenter',
        };
        const returnString = this.functions.httpsCallable('analyticsData');
        return returnString(data);

    }

    getLabTeamUsers(){
        const data: any = {
            labId: this.storageServise.getCookie('lab'),
        };
        const returnString = this.functions.httpsCallable('getLabTeamUsers');
        return returnString(data);

    }

    // getSubColectionList(){
    //     const data: any = {
    //         docPath: `lab/${this.storageServise.getCookie('lab')}`,
    //     };
    //     const returnString = this.functions.httpsCallable('getSubCollectionList');
    //     return returnString(data);
    //
    // }

    getSubColectionList() {
        const data ={docPath: 'lab/museumDental'};
        return this.httpClient.post(this.globals.constUrl + 'getSubCollectionList', data);
    }

     swapMonthandDate(dateStr){
        const strDate= dateStr.split('/')[0];
        const strMonth = dateStr.split('/')[1];
        const strYear = dateStr.split('/')[2];
        return `${strMonth}/${strDate}/${strYear}`;

    }


}
