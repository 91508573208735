export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: "AIzaSyCF9n4EwqdmfoSoNoCEI7T9Zac9qsz5bIw",
        authDomain: "sterilwize.firebaseapp.com",
        databaseURL: "https://sterilwize.firebaseio.com",
        projectId: "sterilwize",
        storageBucket: "sterilwize.appspot.com",
        messagingSenderId: "40086506743",
        appId: 'sterilwize',
        cloudFunctionURL: 'https://us-central1-sterilwize.cloudfunctions.net/',
        websiteUrl: 'https://portal.sterilwize.com/',
        websiteFront:'https://wize.ai/',
    }
};
