import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseListService } from "../../../base/base-list.service";
import { StorageService } from 'app/main/module/common/service/storage.service';
@Injectable({ providedIn: 'root' })
export class CassetteListService extends BaseListService {
    protected searchBy = 'barcode';
    constructor(private afs1: AngularFirestore, private userServiceChild: StorageService) {
        super(afs1, userServiceChild);
        this.table = 'cassette';
        this.orderBy = 'dateTimeNum';
    }

    getSearchQueryWithOutText(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getSearchQueryWithText(): any {

        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .where(this.searchBy, '==', this.searchedText)
            .limit(100));
    }

    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .startAfter(this.latestEntry)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }


    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy, 'desc')
            .startAfter(this.startingEntry)
            .where(this.orderBy, '<', 0)
            .limit(this.ROWS));
    }

    getCassetteById(id): Observable<any[]> {

        const patientCollectionPath = 'lab/' + this.userServiceChild.getCookie("lab") + '/' + 'cassette';;
        return this.afs1.collection(patientCollectionPath, ref => ref.where('id', '==', id)).snapshotChanges()
            .pipe(map(actions => {
                return actions.map((a: any) => {
                    return a.payload.doc.data();
                });
            }));
    }

    getCassetteStatusById(id) {
        const patientCollectionPath = 'lab/' + this.userServiceChild.getCookie("lab") + '/' + 'cassette';
        return new Promise( (resolve, reject)=>{
            return this.afs1.collection(patientCollectionPath).doc(id).get().subscribe(data =>{
                resolve(data.data().status);
            })
        })

    }


    getCollectionPath(): string {
        return 'lab/' + this.userServiceChild.getCookie("lab") + '/' + this.table;
    }

}
