import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LabService} from '../lab.service';
import { Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import { StorageService} from '../../../common/service/storage.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Globals} from '../../../common/global';

@Component({
  selector: 'app-labsetting',
  templateUrl: './labsetting.component.html',
  styleUrls: ['./labsetting.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class LabsettingComponent implements OnInit {

    centresType: any;
    labList: any;
    settingFormOne:FormGroup;
    settingFormTwo:FormGroup;
    settingFormThree:FormGroup;
    settingFormFour:FormGroup;
    currentLab = this.userService.getCookie('lab');
    selectedLab = this.userService.getCookie('lab');
    currentUser = this.userService.getCookie('email');
    currentTimeZone = this.userService.getCookie('defaultTz');
    timeZoneList = ['Asia/Kolkata','America/Toronto'];
    currentCenter = this.userService.getCookie('center');
    selectedCenter = this.userService.getCookie('center');
    role = this.userService.getCookie('role');
    currentRoleData: any;

  constructor(public labService: LabService,
              private formBuilder: FormBuilder, private userService: StorageService,
              private router: Router, public globals: Globals) {}

  ngOnInit(): void {

      this.settingFormOne = this.formBuilder.group({
          labListName: ['', Validators.required],
      });

      this.settingFormTwo = this.formBuilder.group({
          centerListName: ['', Validators.required],
      });

      this.settingFormThree = this.formBuilder.group({
          zoneListName: ['', Validators.required],
      });

      this.labService.getWebRole(this.role).then(roleData => {
            
        this.currentRoleData = roleData;
      
    });
       this.labService.getLabList().then(labList =>{
          this.labList = labList;
          // console.log(this.labList);
          console.log(this.currentLab);

      })


      this.labService.getCenterList().then( (centerData:any) => {
          if(centerData.hasOwnProperty('centres')){
              this.centresType = centerData.centres;
          }else {
              this.centresType = [];
          }
          console.log(this.centresType);
      })

  }

    centerOnChangeVal(centerId){
    console.log(centerId);
    this.currentCenter = centerId;
    }
    
    labOnChangeVal(labId){
    console.log(labId);
    this.currentLab = labId;
    }

    zoneOnChangeVal(event){
        console.log(event);

    }
    updateLab(){
        console.log(this.settingFormOne.value.labListName);
        console.log(this.selectedLab, this.settingFormOne.value.labListName);
        if(this.selectedLab === this.settingFormOne.value.labListName){
            alert('Please change current lab to update.');
            return false;
        }
        const labId: string = this.settingFormOne.value.labListName;
        const labData = { lab: labId };
        console.log(labData);
        this.labService.updateUserLabDetail(labData).then(data => {
            this.userService.setCookie('lab',labId);
            alert(`You have updated your lab with- ${labId}, please login again to proceed.`);
            this.logout();
        })
    }

    updateZone(){
        console.log(this.settingFormThree.value.zoneListName);
        const timeZone: string = this.settingFormThree.value.zoneListName;
        const labData = { defaultTimeZone: timeZone };
        this.labService.updateTimeZone(labData).then(data => {
            this.userService.setCookie('defaultTz',timeZone)
            alert(`Time zone successfully updated`);
        })
    }

    // updateCenter(){
    //     console.log(this.settingFormOne.value.labListName);
    //     const centerId: string = this.settingFormOne.value.centerListName;
    //     this.labService.updateUserLabDetail(centerId).then(data => {
    //         console.log('Hello',data);
    //     })

    // }

    updateCenter(){
        console.log(this.settingFormTwo.value.centerListName);
        console.log(this.selectedCenter, this.settingFormTwo.value.centerListName);
        if(this.selectedCenter === this.settingFormTwo.value.centerListName){
        alert('Please change current center to update.');
        return false;
        }
        const centerId: string = this.settingFormTwo.value.centerListName;
        const centerData = { center: centerId };
        console.log(centerData);
        this.labService.updateUserCenterDetail(centerData).then(data => {
        this.userService.setCookie('lab',centerId);
        alert(`You have updated your center with- ${centerId}, please login again to proceed.`);
        this.logout();
        })
        
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        location.reload(true);

    }

}
