import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../common/global';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit {
    uploadMsdForm: FormGroup;
    title = '';
    msg = '';

    constructor(
        private formBuilder: FormBuilder, private httpClient: HttpClient,
        private userService: StorageService,
        private global: Globals,
    ) { }

    ngOnInit(): void {

        this.uploadMsdForm = this.formBuilder.group({
            imageToUpload: [''],
            titleName: [''],
            labId: [this.userService.getCookie("lab")],
        });

    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.uploadMsdForm.get('imageToUpload').setValue(file);
        }
    }


    onSubmit(titleValue) {

        // indirectly assigning value to title
        this.uploadMsdForm.get('titleName').setValue(titleValue);

        const formData = new FormData();

        formData.append('file', this.uploadMsdForm.get('imageToUpload').value);
        formData.append('title', this.uploadMsdForm.get('titleName').value);
        formData.append('labId', this.uploadMsdForm.get('labId').value);

        // this.httpClient.post<any>(this.global.constUrl + 'uploadFile'
        //     , formData).subscribe(
        //         (res) => {
        //             this.msg = 'Data submitted successfully';
        //         },
        //         (err) => {
        //             // do something
        //         }
        //     );
    }




}
