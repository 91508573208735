import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { CassetteConfig } from '../../common/config/cassetteConfig';

@Injectable({ providedIn: 'root' })

export class LabService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;
    // protected table = 'piFiles';
    protected table = 'config';
    protected orderBy = '';
    protected isOrderByNegative = true;
    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }
    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }

getCenterList(){
        const centerPath = this.getCollectionPath();
        console.log(centerPath);
    return new Promise((resolve, reject) => {
        return this.afs.collection(centerPath).doc('lab').get().subscribe(data =>{
            resolve(data.data());
        })
    })
}

getCassetteConfig(id: string) {
    const getCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/config';
    return this.afs.doc<CassetteConfig>(`${getCollectionPath}/${id}`);
}

getWebRole(role) {
    const collectionPath = "common/" + "config" + "/" + "webRole";
    return new Promise<any>((resolve, reject) => {
        this.afs
            .collection(collectionPath)
            .doc(role)
            .valueChanges()
            .subscribe((data) => {
                resolve(data);
            });
    });
}

 getLabList(){
        const allData = [];
        return new Promise((resolve, reject) => {
         this.afs.collection('lab').get().subscribe(data =>{
            data.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, ' => ', doc.data());
                allData.push(doc.id);
            });
            resolve(allData);
         })
        })


    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie('lab') + '/' + this.table;
    }

    updateMachineFiles(docId, data) {
        const piPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(piPath).doc(docId).update(data).then((resultData) => {
                const result = { msg: 'Updated SuccessFully', status: 'success' };
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    updateUserCenterDetail(centerData) {
        console.log(centerData);
        return new Promise((resolve, reject) => {
        this.afs.collection('user').doc(this.userService.getCookie('email')).update(centerData).then((resultData) => {
        const data = { msg: 'Added SuccessFully', status: 'success' };
        resolve(data);
        }).catch((error) => {
        reject(error);
        });
        });
        }



    updateUserLabDetail(labData) {
        return new Promise((resolve, reject) => {
            this.afs.collection('user').doc(this.userService.getCookie('email')).update(labData).then((resultData) => {
                const data = { msg: 'Added SuccessFully', status: 'success' };
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

    }

    updateTimeZone(data){
        return new Promise((resolve, reject) => {
            this.afs.collection('lab').doc(this.userService.getCookie('lab')).update(data)
                .then( dataOne => {
                    resolve({msg:'TimeZone Updated Successfully'});
                })
        })
    }

    getCassetteSetting(){
        const cassettePath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            return this.afs.collection(cassettePath).doc('cassette').get().subscribe(data =>{
                resolve(data.data().canAssignIfIncubatorPending);
            })
        })
    }

}


