import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FuseSharedModule} from '@fuse/shared.module';
import {ReportComponent} from './report.component';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FunctionsRegionToken, AngularFireFunctionsModule } from '@angular/fire/functions';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';

export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
        monthYearLabel: {year: 'numeric'}
    }
};

const routes = [
    {
        path     : 'report',
        component: ReportComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    declarations: [
        ReportComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        
    ],
    exports     : [
        ReportComponent
    ],
    providers: [{ provide: FunctionsRegionToken, useValue: 'us-central1' }],
})

export class ReportModule
{
}
