import { AfterViewInit, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Cell } from '../cell';
import { CellService } from '../cell.service';
import { Asset } from '../../asset/asset';
import { BaseCellComponent } from '../basecell/basecell.component';
import { StorageService } from 'app/main/module/common/service/storage.service';

const util = require('util');

@Component({
    selector: 'cell-washer',
    templateUrl: './washer.component.html',
    styles: [':host {display: block; border: solid 1px red; width: 100%;height: 100%}']
})
export class WasherComponent extends BaseCellComponent implements AfterViewInit {
    @Input() cell: Cell;
    path: string;

    constructor(private cellService: CellService, private userService: StorageService) {
        super('WasherComponent');
    }


    getImage(type: string, id: string): Observable<Asset> {
        return this.cellService.getBackgroundImage(type, id);
    }

    ngAfterViewInit(): void {
        const cellType: ECellType = this.cellService.getCellType(this.cell.type);
        const washerPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'washer' + '/' + this.cell.viewId;
        this.cellService.getRef(washerPath).valueChanges().subscribe((washer) => {
            const wasterType = washer.type;
            this.fetchAsset(cellType, wasterType);
        });
    }

    fetchAsset(cellType: ECellType, id: string) {
        const path = this.cellService.getAssetPath(cellType, id);
        this.cellService.getRef(path).valueChanges().subscribe((asset) => {
            if (asset) {
                this.path = asset.imageUrl;
            }

        });
    }

    getUrl() {
        return this.path;
    }
}
