import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {StorageService} from '../../../common/service/storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {ApiService} from '../../../common/service/api.service';
import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
// import { EmailListService } from '../../email/list/EmailList.service';
import {LabelhistoryService} from '../labelhistory.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseListComponent} from '../../../base/base-list.component';
import {ConfigService} from '../../../common/config/config.service';
import {Globals} from '../../../common/global';
import {EmailDto} from '../../email/email.dto';
import {Load} from '../../load/load';
import {MatDialog} from '@angular/material/dialog';
import { PaginationService} from '../../../common/pagination/pagination.service';
import { BaseComponent} from '../../../base/base.component';
import {Subscription} from 'rxjs';
import {cond, noop as _noop} from 'lodash-es';
import {SuccessfulComponent} from '../../patient/successful/successful.component';
import {LabelresultpopupComponent} from '../labelresultpopup/labelresultpopup.component';
const timeZone = require('moment-timezone');


@Component({
    selector: 'app-lablelhistory',
    templateUrl: './lablelhistory.component.html',
    styleUrls: ['./lablelhistory.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LablelhistoryComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: LabelhistoryService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        public dialog: MatDialog,
        private globals: Globals,
        private apiService: ApiService,
        public paginationServiceV2: PaginationService,
        private fb1: FormBuilder,
    ) {
        super(configService, globals);
    }
    dataSource: MatTableDataSource<Element>;
    perPageData = this.globals.perPageData;
    // columns = this.getColumn();
    columns: Array<any> = [];
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    // displayedColumns = this.columns.map(c => c.columnDef);
    displayedColumns: Array<any> = [];
    rowData: Array<any> = [];
    labelsAddedDate: string;
    currentLab = this.userSevice.getCookie('lab');
    customColumn: any;
    enableInfoMsg = false;
    limit = 1000;
    full = false;
    hasFirstDataShown = true;
    dataN: Array<any> = [];
    page = 0;
    daysTotal: number

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    protected extraCta = {
        visibility: false,
        text: '',
    };

    todayDate: Date = new Date();

    filterForm:FormGroup;
    startDate = moment().subtract(30, 'days').startOf('day');
    dayAfTomorrow = moment().startOf('day');
    totalLabelData:  Array<any> = [];
    daysCount: Array<any> = [];
    showButton = false;

    loggedinUser = this.userSevice.getCookie('role');

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {


        console.log(this.globals.getLocalTimezoneName());
        // if (this.globals.teamMemberConfig !== 'support') {
        //     this.router.navigate(['/home']);
        //     }

        this.filterForm = this.fb1.group({
            from: [this.startDate, [Validators.required], ''],
            to: [this.dayAfTomorrow, [Validators.required], ''],
            // preOffice:['', [],''],
            // inOffice:['', [],''],
        });

        // this.apiService.getLabCenterList().then(data => {
        //     console.log(data);
        // })


        // testing for label count Total
        // const arr = [ 'CCP', 'BI', 'BD', 'EI', 'CPP', 'CRT', 'ASD', 'ABC' ];
        // const objMat = [];
        // this.apiService.getLabelUsage().then(data => {
        //     console.log('label Called',data);
        //
        // //     for(let key of arr){
        // //         objMat.push({[key]:this.getSum(data.data.value,key)})
        // //     }
        // //
        // // console.log(objMat);
        // })

        this.paginationService.getConfigColumnLabel().then((data: any) => {
             console.log(data);
            const initCol = this.getColumn();
            console.log('initCol', initCol);
            for (const label of data) {
                // console.log(label);
                const customeCol = {
                    columnDef: label,
                    header: label,
                    cell: (element: any, index: number) => this.getLabelCount(element, label)
                };
                // console.log(element);
                initCol.push(customeCol);
            }

            this.columns = initCol;
            console.log('COLUMN=',this.columns);
            this.displayedColumns = this.columns.map(c => c.columnDef);
            console.log('labelAddedon====')
            console.log(this.displayedColumns);
            

        });


        this.page = 0;
        // console.log('columns', this.columns);
        console.log('displayedColumns', this.displayedColumns);
        this.columns = this.getColumn();
        console.log(this.columns);


    }

    // getSum(array, column){
    // let values = array.map((item) => parseInt(item[column], 10) || 0)
    // return values.reduce((a, b) => a + b)
    // }


    ngAfterViewInit(): void {

        // this.apiService.getRoleAndPermission(this.userSevice.getCookie('lab')).then(async ( data:any) => {
        //     this.loggedinUser = data.data.name;
        //     console.log(data.data.role);
        // })
        this.initData();
    }

    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    getColumn(): any[] {

        return [
            {
                columnDef: 'Sr.',
                header: 'Sr.',
                cell: (element: any, index: number) => `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: 'Label AddedOn',
                header: 'Label AddedOn',
                // cell: (element: any, index: number) => `${-1*element.time}`
                cell: (element: any, index: number) => element.doc.id
            },


        ];
    }

    // updateRow(row): any {
    //     row.dateText = moment(Math.abs(row.time)).format('DD-MM-YYYY');
    //     row.timeText = moment(Math.abs(row.time)).format('HH:mm');
    // }
    updateRow(row): any {
        row.dateText = timeZone(Math.abs(row.time)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.timeText = timeZone(Math.abs(row.time)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Label Logs';
    }

    viewLOad(emailObj) {
        // const urlData = this.globals.encodeToa(emailObj.dateText + emailObj.to);
        // const urlData = emailObj.dateText + emailObj.to;
        const urlData = emailObj.id;
        this.router.navigate(['/emaildetail'], {queryParams: {urlData}});
    }


    subscription: Subscription;
    initData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.paginationServiceV2.reset();
        this.paginationServiceV2.init(this.paginationService.getCollectionPath(), 'time', { reverse: false, prepend: false })

        this.subscription = this.paginationServiceV2.data.subscribe(data => {
            console.log(data);
            this.dataN = data;
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if(!this.dataN || data.length === 0){
                this.enableInfoMsg = false;
                this.hasFirstDataShown = false;
                setTimeout(()=> {
                    this.enableInfoMsg = true;
                    this.hasFirstDataShown = true;
                },3000)
            }
        });
        this.page = 0;
    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {
        window.open(Url, '_blank');
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    openDialogWithRef(ref: TemplateRef<any>, row) {
         console.log(row);
        this.rowData.length = 0;
        this.labelsAddedDate = row.doc.id;
        // console.log(this.labelsAddedDate);
        // console.log('row', row);

        this.paginationService.getConfigColumnLabel().then(labelData => {
            console.log(labelData);
            const labels: any = labelData;
            for (const key of labels) {
                if (row[key]) {
                    this.rowData.push({key: key, value: row[key]});
                }else{
                    this.rowData.push({key: key, value: 0});
                }
            }
            // console.log(this.rowData);
            this.dialog.open(ref, {width: '320px', disableClose: true});
        });
    }

    getLabelCount(dateValue, labelVal) {
    //    console.log(dateValue,labelVal)
    //    console.log(dateValue.doc.id);

        if (dateValue[labelVal]) {
            return dateValue[labelVal];
        }
        return '-';
    }

    // lodsh
    handleScroll = (scrolled:boolean ) => {
        console.log('lastScrolled',scrolled);
        scrolled ? this.getData() : _noop();
        // console.time('lastScrolled');
    }
    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;
    }
    getData() {
        this.paginationServiceV2.more();

        // this.dataSource.sort = this.sort;
    }


    // ondestroy
    ngOnDestroy(): void {
    }

    dateRangeFilter() {
        this.showButton = true;
        this.hasFirstDataShown = false;
        const from = parseInt(this.filterForm.value.from.format('x'), 10);
        const to = parseInt(this.filterForm.value.to.format('x'), 10) + 86400000;
        this.daysTotal = ((to - from) / (1000*60*60*24)) ;
        this.daysCount = [moment(from).format('LL') , moment(to).subtract(1,'days').format('LL')];
        this.totalLabelData.length = 0;
        this.apiService.getLabelUsage(from, to).then(data => {
            this.totalLabelData.push(data);
            console.log(this.totalLabelData);
            this.showButton = false;
            this.openSuccessfulDialog(this.totalLabelData);
            })
        this.hasFirstDataShown = true;
    }


    openSuccessfulDialog(data) {
        const dialogRef = this.dialog.open(LabelresultpopupComponent,
            {
                disableClose: true,
                width: '350px',
                // height: '250px',
                data: {labelValue:this.totalLabelData,days:this.daysCount,count:this.daysTotal},

            });
    }


}

