
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '../../../../../../@fuse/services/config.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { CovidstaffService } from '../covidstaff.service';
import { CovidService } from '../../covid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../common/global';
import * as firebase from 'firebase';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {Location} from '@angular/common';
@Component({
    selector: 'app-staffinit',
    templateUrl: './staffinit.component.html',
    styleUrls: ['./staffinit.component.scss']
  })
  export class StaffinitComponent implements OnInit {
    answers={};
    patientForm: FormGroup;
    patientFormEmail: FormGroup;
    allQuestionnaireObj = {};
    signatureImageUrlPat = '';
    inOfficeTemperature = '';
    titleMatIcon = this.getTitleMatIcon();
    spo = '';
    matError = '';
    unitInOffice = '';
    minV: any;
    maxV: any;
    inOffTemperature = '';
    temperatureUnit = 'C';
    alertSign = '';
    hasFirstDataShown: boolean;
    signatureImage = '';
    symptom = '';
    radioErr = '';
    urlStaffId = this.userService.getCookie("email");
    token = '';
    queryParam = {};
    labName = '';
    docId: string;
    key: string;
    staffName = '';
    maxLengthTemp = 5;
    onclicktrue = false;
    href: string;
    showAsterisk:boolean;
    ansObject:Array<any>;

    @ViewChild(SignaturePad) public signaturePad: SignaturePad;
    public signaturePadOptions: object = { // passed through to signature_pad constructor
        'minWidth': 1,
        'canvasWidth': 250,
        'canvasHeight': 150,
        backgroundColor: 'gray',
    };

    public temperatureValidation = [
         Validators.pattern(/^\d*\.?\d*$/),
        (control: AbstractControl) => Validators.max(this.maxV)(control),
        (control: AbstractControl) => Validators.min(this.minV)(control)
    ];
    symptom2: any;
    symptom3: any;
    symptom5: any;
    symptom4: any;
    symptom6: any;
    symptom7: any;
    symptom8: Array<any>;
    check: string;
    symptom1: any;
  staffScreenerName: string;
  

    constructor(private formBuilder: FormBuilder, private _fuseConfigService: FuseConfigService,
        private storage: AngularFireStorage, private covidstaffService: CovidstaffService,
        private route: ActivatedRoute, private router: Router,
        private cookieService: CookieService,
        private userService: StorageService,
        public location:Location,
        public global: Globals, private covidService: CovidService) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }

    ngOnInit(): void {

        // this.href = this.router.url;
        // console.log("Kirti=",this.href);
        // if (this.href.includes("/staffassessment?token=")) {
        // this.showAsterisk = true;
        // console.log(this.showAsterisk);
        // }
        // else {
        // this.showAsterisk = false;
        // console.log(this.showAsterisk);
        // }
       this.staffScreenerName= this.userService.getCookie("staffName")
       console.log(this.staffScreenerName)

        firebase.auth().signInWithEmailAndPassword(this.global.staticEmail, this.global.staticPass)
            .catch(error => {//dp something
            });

        this.patientForm = this.formBuilder.group({
            // Validators.min(36),Validators.max(108)
            inOffTemperature: ['', []],
            temperatureUnit: ['', [Validators.required]],
            spo: ['', [Validators.min(1), Validators.max(100), Validators.maxLength(5), Validators.pattern(/^\d*\.?\d*$/)]],

        });

        this.token = this.route.snapshot.queryParams.token;
        console.log(this.token);
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        // console.log(this.queryParam);

        if(this.queryParam['key'] === 'email'){
            this.showAsterisk = false;
            this.patientForm.get('inOffTemperature').setValidators(this.temperatureValidation);
        }else{
            this.showAsterisk = true;
                this.patientForm.get('inOffTemperature').setValidators(this.temperatureValidation.concat(Validators.required));
        }

        this.covidstaffService.getCovidResultByIdWithoutLogin(this.queryParam['docId'], this.queryParam['labName']).then((data: any) => {
            this.staffName = data.name;
            console.log(this.staffName);
        })
        this.labName = this.queryParam['labName'];

        if (this.queryParam['docId']) {
            this.docId = this.queryParam['docId'];
        }

        if (this.queryParam['key']) {
            this.key = this.queryParam['key'];
        }

        this.hasFirstDataShown = true;
    }
    back(): void {
        this.location.back()
      }
      getTitleMatIcon(): string {
        return 'assignment';
    } 
    showImage(data, pad) {
        console.log('Data=',data,'pad=',pad);
        const ansValue=Object.values(this.answers);
        console.log(ansValue);

    //    if(ansValue.length!=7){
    //        this.radioErr = 'Choose an option';

                
    //     }
    // else{
    
    // if( ansValue.includes('Yes')){
    //       this.symptom = 'Yes';
    //                                }
    //  else{
    //      console.log('Right');
    //      this.symptom = 'No';
    //  }

    this.symptom=localStorage.getItem('staffAns');
          
           console.log( this.symptom);
           this.signatureImage = data;
           console.log( this.signatureImage );
           console.log(pad.signaturePad);
            if (pad.signaturePad.isEmpty() || this.patientForm.invalid || this.symptom.length === 0) {
                if (pad.signaturePad.isEmpty()) {
                    this.alertSign = 'Signature is required';
                    
                } 
                
                if (this.patientForm.invalid) {
                    this.patientForm.markAllAsTouched();
                    if(!this.inOffTemperature){
                        this.setFocusElement('inOffTemperature');
                    }
                } 
                if(this.symptom.length === 0) {
                    this.radioErr = 'Choose an option';
                }
            } else {
                this.hasFirstDataShown = false;
                this.onclicktrue = true;
                this.alertSign = '';
                this.signatureImage = data;
                // console.log( this.signatureImage );
                // localStorage.setItem('signatureImagePat', this.signatureImage);
                if (this.signatureImage) {
                    this.uploadSignature(this.signatureImage)
                        .then((urlData: any) => {
                            if (urlData) {
                                this.signatureImageUrlPat = urlData;
                                if (this.queryParam['key'] === 'email') {
                                    this.allQuestionnaireObj['addedOn'] = -1 * new Date().getTime();
                                    this.allQuestionnaireObj['preOffTime'] = new Date().getTime();
                                    this.allQuestionnaireObj['preStaffSign'] = this.signatureImageUrlPat;
                                    this.allQuestionnaireObj['preOffice'] = this.symptom;
                                    this.allQuestionnaireObj['labName'] = this.queryParam['labName'];
                                    this.allQuestionnaireObj['preSpo'] = this.patientForm.value.spo ? this.patientForm.value.spo : '';
                                    this.allQuestionnaireObj['preOffTemperature'] = this.patientForm.value.inOffTemperature;
                                    this.allQuestionnaireObj['preOffTemperatureUnit'] = this.patientForm.value.temperatureUnit;


                                } else {
                                    // this.allQuestionnaireObj['addedOn'] = -1* new Date().getTime();
                                    this.allQuestionnaireObj['spo'] = this.patientForm.value.spo ? this.patientForm.value.spo : '';
                                    // this.allQuestionnaireObj['name'] = localStorage.getItem('name');
                                    this.allQuestionnaireObj['unitInOffice'] = this.patientForm.value.temperatureUnit;
                                    this.allQuestionnaireObj['staffSign'] = this.signatureImageUrlPat;
                                    this.allQuestionnaireObj['staffName'] = this.userService.getCookie("staffName");
                                    //console.log(this.allQuestionnaireObj['staffName']);
                                    this.allQuestionnaireObj['inOffTemperature'] = this.patientForm.value.inOffTemperature;
                                    this.allQuestionnaireObj['inOffice'] = this.symptom;
                                    this.allQuestionnaireObj['inOffTime'] = new Date().getTime();
                                    this.allQuestionnaireObj['labName'] = this.queryParam['labName'];
                                }

                                const returnData = this.covidstaffService.updateCovidStaffResultWithoutLogin(this.allQuestionnaireObj, this.docId, this.labName);
                                returnData.then(result => {
                                    this.hasFirstDataShown = true;
                                    localStorage.setItem('staffAns', this.symptom);
                                    console.log(this.symptom);
                                    this.router.navigate(['/staffconfirmcard'], { queryParams: { token: this.token } });
                                    // this.viewResult(this.urlPatientId);
                                    const msg = 'Covid screening Data  Updated Successfully';

                                }).catch((error) => {
                                    // this.handleError(error);
                                });
                            }
                        })
                }
            }
        // }
    }
    uploadSignature(fileData) {
        return new Promise((resolve, reject) => {

            const file = fileData.split(',')[1];
            const metadata = {
                contentType: 'image/png',
            };
            const n = Date.now();
            const signatureRef = this.storage.ref(`/signature/${n}.png`);
            signatureRef.putString(file, 'base64', metadata).then(snapshot => {
                if (snapshot) {
                    signatureRef.getDownloadURL().subscribe(url => {
                        resolve(url);

                    });

                }
            }).catch(error => {//dp something
            });

        })
    }




    numericOnlyTemp(event): boolean {
        const unitVal: string = this.patientForm.value.temperatureUnit;
        if (this.patientForm.invalid) {
            this.patientForm.markAllAsTouched();
        }
        if (unitVal === 'C') {
            this.minV = 25;
            this.maxV = 40;
            this.matError = '34-39C';
        } else if (unitVal === 'F') {
            this.maxLengthTemp = 6;
            this.minV = 85;
            this.maxV = 110;
            this.matError = '96-108F';
        }


        const patt = /^\d*\.?\d*$/;
        const result = patt.test(event.key);
        return result;

    }

    onChangeSelect(eventVal) {
        this.inOffTemperature = '';
        if (eventVal === 'C') {
            this.minV = 25;
            this.maxV = 40;
            this.matError = '34-39C';
        } else if (eventVal === 'F') {
            this.minV = 85;
            this.maxV = 110;
            this.matError = '96-108F';
        }

        // this.matError = '';
    }

    
   
    setFocus(id) {
        const element = document.getElementById(id);
        // element.focus();
        console.log(element);
        element.scrollIntoView();

    }

    setFocusElement(id) {
        const element = document.getElementById(id);
        element.focus();

    }

}

