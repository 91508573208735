import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {Observable, Subject} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';
import {MatTableDataSource} from '@angular/material/table';
import {StorageService} from '../../../../common/service/storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CovidDto} from '../../../CovidDto.modal';
import {MatDialog} from '@angular/material/dialog';
import {Globals} from '../../../../common/global';
import {ScreeningPatientHistoryListService} from './screening-patient-history-list.service';
import { CovidService } from '../../../covid.service';
import { ApiService } from 'app/main/module/common/service/api.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
const timeZone = require('moment-timezone');


@Component({
    selector: 'app-covidviewdetail',
    templateUrl: './screening-patient-history-list.component.html',
    styleUrls: ['./screening-patient-history-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ScreeningPatientHistoryListComponent  implements OnInit, OnDestroy, AfterViewInit {

    // dataSource = new MatTableDataSource<any>();
    // displayedColumns: string[] = ['Sr', 'ID', 'First Name', 'Last Name'];
    patient: string;
    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    searchText: string;
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    urlPatientId: any;
    urlLabName = this.userSevice1.getCookie("lab");
    perPageData = this.global.perPageData;
    token = '';
    queryParam = {};
    firstName = '';
    lastName = '';
    fullName = '';

    @ViewChild('filter', { static: true })
    filter: ElementRef;
    length: any;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    searchTxt = '';
    hasFirstDataShown = true;
    enableInfoMsg = false;

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;

    protected extraCta = {
        visibility: false,
        text: '',
    };
    filterForm: FormGroup;
    startDate = moment().startOf('day');
    dayAfTomorrow = moment().add(2, 'days').startOf('day');
    hasSearchedText: boolean;
    hasDateRange: boolean;
    condition: { from: number; to: number; };
    lastSnackTime: number;
    

    constructor(
        private userSevice1: StorageService, private fb1: FormBuilder,
        private route1: ActivatedRoute, private router1: Router,
        private route: ActivatedRoute, private router: Router,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private paginationService: ScreeningPatientHistoryListService,
        public covidService: CovidService,
        private changeDetectorRefs: ChangeDetectorRef,
        public global: Globals,
        public apiService: ApiService,
        public snackBar: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();
        // if(localStorage.getItem('staticData') === 'Y' ){
        this.extraCta.visibility = true;
        this.extraCta.text = 'Start';
        // }

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
       // this.route.snapshot.paramMap.get('id')
        this.page = 0;
        this.token = this.route.snapshot.queryParams.token;
        console.log(this.token);
        if (this.token) {
            const keyVal = this.global.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        this.urlPatientId = this.queryParam['patient'];
        this.firstName = this.queryParam['firstName'] ? this.queryParam['firstName'] : '';
        this.lastName = this.queryParam['lastName'] ? this.queryParam['lastName'] : '';

        this.fullName = this.firstName + ' ' + this.lastName;
        this.paginationService.patientId = this.urlPatientId;
        console.log(this.paginationService.patientId);
        // query param for back button

        if (this.queryParam['searchTxt']) {
            this.searchTxt = this.queryParam['searchTxt'];
            sessionStorage.setItem('searchPat', this.searchTxt);
        } else {
            sessionStorage.setItem('searchPat', '');
        }

        this.filterForm = this.fb1.group({
            from: [this.startDate, [Validators.required], ''],
            to: [this.dayAfTomorrow, [Validators.required], ''],

        });
    }

  
    dateRangeFilter() {
        this.hasFirstDataShown = false;
        const from = parseInt(this.filterForm.value.from.format('x'), 10);
        const to = parseInt(this.filterForm.value.to.format('x'), 10) + 86400000;
         this.condition = { from: from, to: to };
        // console.log(this.condition,this.paginationService.patientId)
        this.paginationService.searchRange(this.condition,this.urlPatientId);
        this.hasFirstDataShown = true;
    }

    openSnackBar(message: string, action: string): void {
        const currentTime = (new Date()).getTime();
        if (currentTime - this.lastSnackTime < 6000) {
            return;
        }
        this.lastSnackTime = currentTime;
        this.snackBar.open(message, action, {
            duration: 6000,
        });
    }
    // onClickSearch() {
    //     // alert('Search Text' + this.searchText);
    //     this.paginationService.searchedText = this.searchText;
    //     this.paginationService.search();
    // }

    ngAfterViewInit(): void {

        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {

            if (data === null) {
                this.dataSource.data=[];
                this.openSnackBar('No data found', 'OK');
            }

            if (data && data.length > 0) {
                this.hasFirstDataShown = true;
                this.length = data.length;
                this.dataSource.data = data;

                if (this.searchText && this.searchText.length > 0) {
                    this.hasSearchedText = true;
                    const matTable = document.getElementById('matTable');
                    matTable.scrollTop = 0;
                } else {
                    this.hasSearchedText = false;
                }
                if(this.condition){
                    this.hasDateRange =true;
                }
                else{
                    this.hasDateRange =false; 
                }
            }else {
                this.hasSearchedText = true;
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(()=>{
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                },6000)
            }

        });

    }

    nextPage(): void {

        this.paginationService.next();
    }


    prevPage(): void {
        this.paginationService.prev();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();



    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }



    getColumn(): any[] {
        return [
            {
                columnDef: 'Sr',
                header: 'Sr.',
                cell: (element, index: number) => `${this.page * 10 + index + 1}`,

            },
            { columnDef: 'DOA', header: 'DateTime Of Appointment', cell: (element: CovidDto, index: number) => `${element.doa ? this.apiService.getDateStringMomentByTz(Number(element.doa)) : ''}` },
            // {columnDef: 'Time', header: 'Time', cell: (element: CovidDto, index: number) => this.getTime(Math.abs(element.addedOn))},
            // { columnDef: 'MSD', header: 'DateTime of email sent', cell: (element: CovidDto, index: number) => `${element.addedOn ? this.apiService.getDateStringMomentByTz(element.addedOn) : ''}` },
            { columnDef: 'ID', header: 'ID', cell: (element: CovidDto, index: number) => `${element.patientId}` },

            { columnDef: 'First Name', header: 'First Name', cell: (element: CovidDto, index: number) => `${element.firstName}` },
            { columnDef: 'Last Name', header: 'Last Name', cell: (element: CovidDto, index: number) => `${element.lastName}` },
            { columnDef: 'Email', header: 'Email', cell: (element: CovidDto, index: number) => `${element.email ? element.email : ''}` },
            { columnDef: 'Phone', header: 'Phone', cell: (element: CovidDto, index: number) => `${element.phone ? element.phone : ''}` },
            // {columnDef: 'Result', header: 'Result', cell: (element: CovidDto, index: number) => `Negative`},
            // {columnDef: 'Modify', header: 'InOffice', cell: (element: CovidDto, index: number) => this.enableInOffice(element.docId, element.login )},
            { columnDef: 'PreScreening', header: 'PreScreening', cell: (element: CovidDto, index: number) => this.isPreOfficeDone(element.docId, element.preOfficeTime, element.login) },
            { columnDef: 'In Office', header: 'In Office', cell: (element: CovidDto, index: number) => this.isInOfficeDone(element.docId, element.inOffice) },

            { columnDef: 'Details', header: 'Details', cell: (element: CovidDto, index: number) => `${element.docId}` },
        ];
    }


    enableInOffice(docId, login) {

        if (login === undefined) {
            return docId;
        }

    }

    prepareComboId(id, fname, lname) {
        let combo = id + fname + lname;
        return combo = combo.replace(/\s/g, '').toLowerCase();

    }

    isPreOfficeDone(docId, preOfficeTime, login) {

        if (preOfficeTime === undefined && login === undefined) {
            return 'Pending';
        }
        return 'Done';
    }

    isInOfficeDone(docId, inOffice) {
        if (inOffice === true) {
            return 'Done';
        } else {
            return 'Pending';
        }

    }

    // getDate(timestamp){
    //     const customDate = (new Date(timestamp).toLocaleDateString());
    //     return customDate;
    // }
    // getTime(timestamp){
    //     const hrs = (new Date(timestamp).getHours());
    //     const mnts = (new Date(timestamp).getMinutes());
    //     return hrs+' : '+mnts;
    // }


    getOrderByColumn(): string {
        return 'doaTimestamp';
    }

    getTableName(): string {
        return 'patientResult';
    }

    updateRow(row): any {
        // setDeriveFields(row);
    }

    getTitleMatIcon(): string {
        return 'assignment';
    }

    getTitle(): string {
        return 'Covid Assessment Screening';
    }

    getSearchText(): string {
        return 'Search Patient via Id/Name';
    }

    extraButtonClick(): void {
    }

    editButtonClick(comboId): void {
        const urlStrGuest = 'key#edit##patient#' + comboId + '##labName#' + this.urlLabName;
        // this.router.navigate(['/covid-assessment'], { queryParams: { key:'edit', patient: this.global.encodeToa(comboId) , labName: this.global.encodeToa(this.urlLabName) }});
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStrGuest) } });

    }

    viewResult(comboId) {

        const tokenStr = 'patient#' + comboId + '##labName#' + this.urlLabName;

        this.router.navigate(['/covidresultdetail'], { queryParams: { token: this.global.encodeToa(tokenStr) } });
    }

    startAssessment() {
        const lab = this.userSevice1.getCookie("lab")
        const urlStr = 'key#start##labName#' + this.urlLabName + '##type#direct';
        this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStr) } });
        // this.router.navigate(['/covid-assessment-start'], { queryParams: { key: 'start', labName: this.global.encodeToa(lab), type:'direct'}});


    }


}