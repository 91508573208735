import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Team } from './team';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../common/service/authentication.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { Globals } from '../../common/global';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../common/service/api.service';
import {Router, RouterStateSnapshot, ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { firestore } from 'firebase';

@Injectable()
export class TeamService  implements Resolve<Team>{
    private itemsSubject: BehaviorSubject<Team[]>;
    public itemsObservable: Observable<Team[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    // onDataChanged: BehaviorSubject<any>;

    ROWS = 100; // per page data
    orderBy = 'sortBy';
    table = 'team';
    currentPage = 0;
    private infoMsg: string;
    labName = '';
    data: any;
    allNames: Array<any> = [];
    // routeParams: any;

    labsObject = [];
    labsData = {
        labId: undefined,
        labName: undefined,
        role: 'NA',
        // hasLimit:false,
        // validTillDate: null

    };

    public notFoundSubject: BehaviorSubject<boolean>;
    public notFoundObservable: Observable<boolean>;
    userName: any;

    constructor(private afs: AngularFirestore,
        public authenticationService: AuthenticationService,
        private userService: StorageService,
        public globals: Globals,
        public apiService: ApiService,
    ) {

        // this.onDataChanged = new BehaviorSubject({});
    }


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
           return  this.apiService.getRoleAndPermission(this.userService.getCookie('lab')).then(returnData =>{
               return returnData;

           })
    }

    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    getCollection(ref, queryFn?): Observable<any[]> {
        return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
            return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                const doc = a.payload.doc;
                return { id, ...data as {}, doc };
            });
        }));
    }

    first(): void {
        this.currentPage = 0;
        this.itemsSubject = new BehaviorSubject([]);
        this.itemsObservable = this.itemsSubject.asObservable();
        this.pageSubject = new BehaviorSubject<number>(0);
        this.pageObservable = this.pageSubject.asObservable();
        this.notFoundSubject = new BehaviorSubject<boolean>(false);
        this.notFoundObservable = this.notFoundSubject.asObservable();
        this.currentPage = 0;
        this.pageSubject.next(0);
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .orderBy(this.orderBy)
            .limit(this.ROWS))
            .subscribe(data => {
                // this.pageSubject.next(0);
                try {
                    // console.log('Data',data);
                    if (data && data.length) {
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.itemsSubject.next(data);

                    }
                } catch (e) {
                }
                // this.itemsSubject.next(data);
                // this.pageSubject.next(0);
                // if (data) {
                // this.latestEntry = data[data.length - 1].doc;
                // this.startingEntry = data[0].doc;
                // this.itemsSubject.next(data);
                // } else {
                // }
                // scoresRef.unsubscribe();
            });
    }

    next(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            .startAfter(this.latestEntry)
            .limit(this.ROWS))
            .subscribe(data => {
                try {
                    if (data && data.length) {
                        this.currentPage++;

                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) {
                }
                this.itemsSubject.next(data);


                // if (data.length) {
                //   this.currentPage++;
                //   this.pageSubject.next(this.currentPage);
                //   // And save it again for more queries
                //   this.latestEntry = data[data.length - 1].doc;
                //   this.startingEntry = data[0].doc;
                //   this.itemsSubject.next(data);
                // }
                // scoresRef.unsubscribe();
            });
    }


    prev(): void {
        const ref = this.getCollection(this.getCollectionPath(), ref => ref
            // Now you can use the latestEntry to query with startAfter
            .startAfter(this.startingEntry)
            .limit(this.ROWS))
            .subscribe(data => {

                try {
                    if (data && data.length) {
                        data.reverse();
                        // And save it again for more queries
                        this.latestEntry = data[data.length - 1].doc;
                        this.startingEntry = data[0].doc;
                        this.currentPage--;
                        this.pageSubject.next(this.currentPage);
                        this.itemsSubject.next(data);
                    }
                } catch (e) { // dp something
                }
                this.itemsSubject.next(data);

                //
                // data.reverse();
                // // And save it again for more queries
                // this.latestEntry = data[data.length - 1].doc;
                // this.startingEntry = data[0].doc;
                // this.currentPage--;
                // this.pageSubject.next(this.currentPage);
                // this.itemsSubject.next(data);

                // scoresRef.unsubscribe();
            });
    }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie('lab') + '/' + this.table;
    }

    checkTeammember(teampath, email) {
        return this.afs.collection(teampath).doc(email).get();
    }

    addLabsObject(teamData, expiryData) {
        return new Promise((resolve, reject) => {
            this.afs.collection('lab').doc(teamData.lab).get().subscribe((result: any) => {
                const resultData = result.data();
                this.labsData.labId = teamData.lab;
                this.labsData.labName = resultData.name;
                this.labsData.role = teamData.role;
                // this.labsData.hasLimit = expiryData.hasLimit;
                // this.labsData.validTillDate = expiryData.validTillDate;
                this.labsObject.push(this.labsData);
                resolve(this.labsObject);
            });
        });
    }

    // checking element in array of objects
    checkLabsObjectExist(arr, labId) {
        return arr.some((el) => {
            return el.labId === labId;
        });
    }


    addTeamMember(teamData: any, expiryData: any) {
        const email = teamData.email;
        const teamPath = `lab/${teamData.lab}/team/`;
        return new Promise((resolve, reject) => {
            this.afs.collection('user').doc(email).get().subscribe(async (doc: any) => {
                if (doc.data() !== undefined) {
                    const userData = doc.data();
                    if (userData.labs !== undefined && !userData.labs.includes(teamData.lab)) {
                        const labs = userData.labs.push(teamData.lab);
                        this.afs.collection('user').doc(email).set({ labs: labs });
                        this.infoMsg = 'Lab mapped with user';

                    } else if (userData.labsObject !== undefined && !this.checkLabsObjectExist(userData.labsObject, teamData.lab)) {

                        const labsObjectData = await this.addLabsObject(userData, expiryData);

                        this.afs.collection('user').doc(email).set({ labsObject: labsObjectData });
                        this.infoMsg = 'Lab mapped with user';

                    } else if (userData.labs === undefined) {
                        userData.labs = [];
                        userData.labs.push(teamData.lab);
                        this.afs.collection('user').doc(email).set({ labs: userData.labs });
                        this.infoMsg = 'Lab mapped with user';
                    } else if (userData.labsObject === undefined) {
                        // SOC adding labs Object like labName, labId and role
                        teamData.labsObject = [];
                        const labsObjectData = await this.addLabsObject(userData, expiryData);
                        teamData.labsObject = labsObjectData;
                        // EOC
                        this.afs.collection('user').doc(email).update({ labsObject: teamData.labsObject });
                        this.infoMsg = 'Lab mapped with user';
                        // window.alert(`Lab mapped with user`);

                    }
                    this.checkTeammember(teamPath, email).subscribe(async isAvaible => {

                        if (!isAvaible.exists) {
                            const labsObjectData = await this.addLabsObject(userData, expiryData);
                            teamData.labsObject = labsObjectData; // Assigning labsObject Data
                            this.afs.collection(teamPath).doc(email).set(teamData).then((resultData) => {
                                // window.alert(`Team Member ${teamData.name} Added Successfully`);
                                this.data = { msg: 'Added SuccessFully', status: 'success' };
                            });
                        } else {
                            window.alert(`Team member with ${teamData.email} is Already Exist`);
                            this.data = { msg: `Team member with ${teamData.email} is Already Exist`, status: 'success' };
                        }
                    });

                    resolve(this.data);

                } else {
                    // creating labs array and adding lab
                    teamData.labs = [];
                    teamData.labs.push(teamData.lab);
                    // SOC adding labs Object like labName, labId and role
                    teamData.labsObject = [];
                    const labsObjectData = await this.addLabsObject(teamData, expiryData); // function called
                    // const labsObjectData = await this.getLabNameById(teamData)
                    teamData.labsObject = labsObjectData;

                    // EOC

                    this.afs.collection('user').doc(email).set(teamData).then((resultData) => {
                        this.data = { msg: 'Added SuccessFully', status: 'success' };
                    }).catch((error) => {
                        // window.alert(`Server Error!, Member Not Added`);
                        this.data = { msg: 'Server Error!, Member Not Added ', status: 'fail' };
                    });
                    this.afs.collection(teamPath).doc(email).set(teamData).then((resultData) => {
                        // window.alert(`Team Member ${teamData.name} Added Successfully`);
                        this.data = { msg: 'Added SuccessFully', status: 'success' };
                        resolve(this.data);

                    }).catch((error) => {
                        reject(error);
                    });
                }
            });
        });
    }

    // backup old one
    addTeamMember12(teamData: any) {
        const email = teamData.email;
        const teamPath = `lab/${teamData.lab}/team/`;
        return new Promise((resolve, reject) => {
            this.afs.collection('user').doc(email).get().subscribe(async (doc: any) => {
                if (doc.data() !== undefined) {
                    const userData = doc.data();

                    window.alert(`${teamData.email} Already Exist`);

                    resolve(userData);

                } else {
                    // this.afs.collection('user').doc(email).set(teamData).then( (resultData) => {
                    //     const data = {msg: 'Added SuccessFully', status: 'success'};
                    // });
                    this.afs.collection(teamPath).add(teamData).then((resultData) => {
                        console.log(resultData);
                        // window.alert(`Team Member ${teamData.name} Added Successfully`);
                        const data = { msg: 'Added SuccessFully', status: 'success' };
                        resolve(data);

                    }).catch((error) => {
                        reject(error);
                    });
                }
            });
        });
    }

    getMemberById(docId) {
        const memberCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(memberCollectionPath).doc(docId).valueChanges().subscribe(data => {
                resolve(data);
            });
        });       
    }

    updateTeamMember(teamData) {

        const teamPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            this.afs.collection(teamPath).doc(teamData.email).update(teamData).then((resultData) => {
                // window.alert(`Team Member ${teamData.name} Added Successfully`);
                const data = { msg: 'Updated SuccessFully', status: 'success' };
                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });
    }

    updateUserWhenTeamUpdated(teamData) {
        return new Promise((resolve, reject) => {
            delete teamData.sortBy;
            this.afs.collection('user').doc(teamData.email).update(teamData).then((resultData) => {
                const data = { msg: 'Added SuccessFully', status: 'success' };
                resolve(data);

            }).catch((error) => {
                reject(error);
            });
        });

    }


    allTeamData() {
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(this.getCollectionPath()).valueChanges().subscribe(data => {
                if (data) {
                    resolve(data.map((value: any) => value.name));
                }
            });
        });
    }

    getStaffMember(docId)  {
        const patientCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/covid/result/' + 'staffResult';
        return new Promise((resolve, reject) => {
            const ref = this.getCollection(patientCollectionPath, ref => ref
                .where('name', '==', docId))
                .subscribe(data => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(null);
                    }

                });
        });
    }


    getTeamData(email) {
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(this.getCollectionPath()).doc(email).valueChanges().subscribe(data => {
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    deleteDocumentById(docId) {
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(this.getCollectionPath()).doc(docId).delete().then((result) => {
                const msg = 'Team Member Successfully Deleted';
                this.currentPage = 0;
                resolve(msg);
            }).catch((error) => {
                reject('Error removing document');
            });
        });
    }
    deleteUserWhenTeamdeleted(docId) {
        return new Promise<any>((resolve, reject) => {
            this.afs.collection('user').doc(docId).delete().then((result) => {
                const msg = 'Team Member Successfully Deleted';
                this.currentPage = 0;
                resolve(msg);
            }).catch((error) => {
                reject('Error removing document');
            });
        });

    }
   getuserName(email){
        return new Promise<any>((resolve, reject) => {
           // this.afs.collection('user').doc(email).snapshotChanges().subscribe(data => {
            this.afs.collection('user').doc(email).valueChanges().subscribe(data => {
                console.log(data);
               //this.userName=data.name
            });
        });
    }

    getWebRole(role) {
        const collectionPath = "common/" + "config" + "/" + "webRole";
        return new Promise<any>((resolve, reject) => {
            this.afs
                .collection(collectionPath)
                .doc(role)
                .valueChanges()
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
    
    getAllRole() {
        const allData = [];
        return new Promise<any>((resolve, reject) => {
            const collectionName = 'common/config/webRole';
            this.afs.collection(collectionName).get().subscribe(roleData => {
                if (roleData) {
                    roleData.forEach(mapD => {
                        let data = {};
                        data = mapD.data();
                        allData.push(data);
                       
                    })
                    resolve(allData);
                } else {
                    reject(' Error in fetching the role details');
                }
            })
        });

    }


    shareAssessmentLink(email: string): Observable<any> {
        const lab = this.userService.getCookie('lab');
        const data: any = {
            email: email,
            labId: lab,
        };

        return this.apiService.shareStaffAssessmentLink(data);

    }

    getCovidConfig() {
        const getCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/covid';
        return new Promise<any>((resolve, reject) => {
            this.afs.collection(getCollectionPath).doc('config').valueChanges().subscribe(data => {
                if (data) {
                    resolve(data);
                }
            });
        });

    }


}
