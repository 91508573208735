import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { Globals } from '../../../common/global';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { RcenterService } from "./rcenter.service";
import { Rcenter } from "./rcenter";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
    selector: 'base-table',
    templateUrl: 'rcenter.component.html',
    styleUrls: ['rcenter.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RcenterComponent implements OnInit, OnDestroy, AfterViewInit {

    page: any;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: false,
        text: 'Add MSD',
    };
    enableInfoMsg = false;
    hasFirstDataShown = false;

    constructor(public paginationService: RcenterService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute, private router: Router, private global: Globals) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            this.length = data.length;
            if (data && data.length > 0) {
                // data.forEach(value => this.updateRow(value));
                this.dataSource.data = data;
            }else {

                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(()=>{
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                },6000)
            }

            // data.forEach(value => this.updateRow(value));
            this.dataSource.data = data;
            // this.filterDataByType(data);
        });

    }

    nextPage(): void {
        this.paginationService.next();
    }


    prevPage(): void {
        this.paginationService.prev();
    }
    uploadInputChange(fileInputEvent: any) {
        // do something
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.page = 0;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: Rcenter, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'title', header: 'Title', cell: (element: Rcenter, index: number) => `${element.title}` }
        ];
    }

    getTableName(): string {
        return 'rcenter';
    }

    updateRow(row): any {
    }

    getTitleMatIcon(): string {
        return 'help_center';
    }

    getTitle(): string {
        return 'Resource Center';
    }

    getOrderByColumn(): string {
        return 'title';
    }

    openDetail(row) {
        this.router.navigate(['/rcenterDetail'], { queryParams: { center: row.id } });
    }

}