import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Patient } from "../patient";
import { BaseListService } from "../../../base/base-list.service";
import { StorageService } from "../../../common/service/storage.service";

@Injectable()
export class PatientListService
    extends BaseListService
    implements Resolve<string>
{
    constructor(
        private afs1: AngularFirestore,
        private userServiceChild: StorageService
    ) {
        super(afs1, userServiceChild);
        this.table = "patient";
        this.orderBy = "firstName";
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.userServiceChild.getCookie("navigationType");
    }

    getSearchQueryWithText(): any {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                .where(
                    this.patientSearchQuery(
                        this.searchedText
                            .replace(/[^a-zA-Z0-9]/g, "")
                            .toUpperCase()
                    ),
                    "==",
                    true
                )
                .limit(1000)
        );
    }

    getSearchQueryWithOutText(): any {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                // .where("deleted", "==", false)
                .orderBy(this.orderBy)
                .limit(this.ROWS)
        );
    }

    getFirstQuery(): any {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                // .where("deleted", "==", false)
                .orderBy(this.orderBy)
                .limit(this.ROWS)
        );
    }

    getNextQuery(): any {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                // .where("deleted", "==", false)
                .orderBy(this.orderBy)
                .startAfter(this.latestEntry)
                .limit(this.ROWS)
        );
    }

    firstItem(id: any): Observable<any[]> {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                .where("rowId", ">", id)
                // .where("deleted", "==", false)
                .limit(this.ROWS)
        );
    }

    nextItem(id: any): Observable<any[]> {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                .where("rowId", ">", id)
                // .where("deleted", "==", false)
                .limit(this.ROWS)
        );
    }

    prevItem(id: any): Observable<any[]> {
        console.log("hijihb   " + id);
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                .orderBy("rowId", "desc")
                // .where("deleted", "==", false)
                .where("rowId", "<", id)
                .limit(this.ROWS)
        );
    }

    getPrevQuery(): any {
        return this.getCollection(this.getCollectionPath(), (ref) =>
            ref
                .orderBy(this.orderBy, "desc")
                // .where("deleted", "==", false)
                // Now you can use the latestEntry to query with startAfter
                .startAfter(this.startingEntry)
                .limit(this.ROWS)
        );
    }

    getCollectionPath(): string {
        return (
            "lab/" + this.userServiceChild.getCookie("lab") + "/" + "patient"
        );
    }

    patientSearchQuery(patient): string {
        return "anyMap." + patient;
    }

    getPatientData() {
        const patientCollectionPath = this.getCollectionPath();
        return new Promise((resolve, reject) => {
            const data1 = this.getCollection(
                patientCollectionPath,
                (ref) => ref
            ).subscribe((data) => {
                if (data && data.length) {
                    resolve(data);
                } else {
                    resolve(null);
                }
            });
        });
    }
    getLab() {
        console.log("calling getlab");
        const getCollectionPath =
            "lab/" + this.userServiceChild.getCookie("lab") + "/config";
        return new Promise<any>((resolve, reject) => {
            this.afs1
                .collection(getCollectionPath)
                .doc("lab")
                .valueChanges()
                .subscribe((data) => {
                    console.log("data" + data);
                    if (data) {
                        resolve(data);
                    }
                });
        });
    }

    /*  getLab() {
        console.log('calling getlab');
        const getCollectionPath = 'lab/' + this.userServiceChild.getCookie('lab') + '/config';
        return new Promise<any>((resolve, reject) => {
        this.afs1.collection(getCollectionPath).doc('lab').valueChanges().subscribe(data => {
        console.log('data'+data);
        if (data) {
        resolve(data);
        }
        });
        });
        
        } */
}
