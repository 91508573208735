import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { Globals } from '../../../common/global';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { RcenterDetailService } from "./rcenterDetail.service";
import { RcenterDetail } from "./rcenterDetail";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from 'app/main/module/common/service/storage.service';


@Component({
    selector: 'base-table',
    templateUrl: 'rcenterDetail.component.html',
    styleUrls: ['rcenterDetail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RcenterDetailComponent implements OnInit, OnDestroy, AfterViewInit {

    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: false,
        text: '',
    };
    urlData = '';
    cstObservableData: Observable<any>;
    constructor(public paginationService: RcenterDetailService,
        private changeDetectorRefs: ChangeDetectorRef,
        private userService: StorageService,
        private route: ActivatedRoute, private router: Router, private global: Globals) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.urlData = this.route.snapshot.queryParams.center;
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setRCenterName(this.urlData);
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {

            this.length = data.length;
            if (data && data.length > 0) {
                this.dataSource.data = data;
            }
            // this.datas = data;
            // data.forEach(value => this.updateRow(value));

            // this.filterDataByType(data);
        });


        const centerPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'rcenter' + '/' + this.urlData;
        this.paginationService.getRef(centerPath).valueChanges().subscribe((resultData) => {
            this.title = resultData.title;
        });
        // this.cstObservableData =  this.paginationService.getCenterById(this.urlData);
        // this.cstObservableData.subscribe(resultData => {
        //
        // });
    }

    nextPage(): void {
        this.paginationService.next();
    }


    prevPage(): void {
        this.paginationService.prev();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: RcenterDetail, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'title', header: 'Title', cell: (element: RcenterDetail, index: number) => `${element.title}` }
        ];
    }

    getTableName(): string {
        return 'rcenter';
    }

    updateRow(row): any {
    }

    getTitleMatIcon(): string {
        return 'label';
    }

    getTitle(): string {
        return 'Resource Center';
    }

    getOrderByColumn(): string {
        return 'priority';
    }


    openDetail(row) {
        window.open(row.link, "_blank");
    }
}