import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './home.component';
import { HomeService} from './home.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { SharedModule} from '../../common/shared.module';
import { NgxChartsModule} from '@swimlane/ngx-charts';
import { ChartsModule} from 'ng2-charts';
import {AuthGuard} from '../../auth/auth.guard';

const routes = [
    {
        path     : 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        resolve  : {
            todo: HomeService
        }
    }
];

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        ChartsModule,
        NgxChartsModule,
        FuseWidgetModule
    ],
    exports     : [
        HomeComponent
    ],
    providers   : [
        HomeService
    ]
})

export class HomeModule
{
}
