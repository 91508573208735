import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/fr';

@Component({
    selector   : 'alert',
    templateUrl: './alert.component.html',
    styleUrls  : ['./alert.component.scss']
})
export class AlertComponent
{

    isChecked = true;
    formGroup: FormGroup;
    bd = true;
    ei = true;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        formBuilder: FormBuilder
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.formGroup = formBuilder.group({
            enableWifi: '',
            acceptTerms: ['', Validators.requiredTrue]
        });
    }


    onFormSubmit = () => {
        alert(JSON.stringify(this.formGroup.value, null, 2));
    }
}
